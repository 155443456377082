/** @format */

import React, { useEffect, useRef, useState } from "react";
import {
  Stack,
  Button,
  Grid,
  Typography,
  Box,
  Modal,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as moment from "moment";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { ListUserChecklistApi } from "../../redux/actions/User";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { date } from "yup";
import CloseIcon from "@mui/icons-material/Close";

const downloadAllstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  maxHeight: "calc(100vh - 100px)",
  overflowY: "auto",
  bgcolor: "background.paper",
  borderRadius: "6px",
  boxShadow: 24,
  p: 4,
};

const MenuProps = {
  disableScrollLock: true,
};

const typeOptions = [
  // { value: "checklist", label: "Checklist" },
  // { value: "lms", label: "LMS" },
  { value: "issueLog", label: "Issue Log" },
  // { value: "customerLog", label: "Customer Log" },
  // { value: "logEntry", label: "Log Entry" },
];

const escalationOptions = [
  { value: "escalationOne", label: "Escalation One" },
  { value: "escalationTwo", label: "Escalation Two" },
  { value: "escalationThree", label: "Escalation Three" },
  // { value: "escalationWeeklyReminder", label: "Escalation Weekly Reminder" },
];

const Header = (props) => {
  const storedDateString = localStorage.getItem("storedDateData1");
  let storedDateObject;
  const searchInputRef = useRef(null);
  const [userListBasedOnStore, setuserListBasedOnStore] = useState([]);
  const { defaultStartDate, defaultEndDate, dateRange, onApplyFilters } = props;

  const userList = useSelector(
    (state) =>
      state.user &&
      state.user.data &&
      state.user.data.data &&
      state.user.data.data.data &&
      state.user.data.data.data.users
  );

  const storeList = useSelector(
    (state) =>
      state.store &&
      state.store.data &&
      state.store.data.data &&
      state.store.data.data.data &&
      state.store.data.data.data.stores
  );

  const storeListBasedOnRole = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.storeData
  );

  const storeListBasedLoader = useSelector(
    (state) => state?.user && state?.user?.loading
  );

  const userListBasedOnStore1 = useSelector(
    (state) =>
      state?.user &&
      state?.user?.userChecklistData &&
      state?.user?.userChecklistData?.data &&
      state?.user?.userChecklistData?.data?.data &&
      state?.user?.userChecklistData?.data?.data?.userIds
  );

  useEffect(() => {
    if (userListBasedOnStore1) {
      setuserListBasedOnStore(userListBasedOnStore1);
    }
  }, [userListBasedOnStore1]);

  try {
    storedDateObject = storedDateString
      ? JSON.parse(storedDateString)
      : { start_date: null, end_date: null };
  } catch (error) {
    storedDateObject = { start_date: null, end_date: null };
  }

  const [start_date, setstart_date] = React.useState(
    storedDateObject.start_date || defaultStartDate
  );
  const [end_date, setend_date] = React.useState(
    storedDateObject.end_date || defaultEndDate
  );
  const [newDate, setNewDate] = React.useState(
    storedDateObject.start_date && storedDateObject.end_date
      ? {}
      : { start_date: defaultStartDate, end_date: defaultEndDate }
  );

  const [showSchedular, setShowSchedular] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [filterRole, setFilterRole] = React.useState(0);
  const [filterUser, setFilterUser] = React.useState([]);
  const [filterStore, setFilterStore] = React.useState([]);
  const [openFilterModal, setOpenFilterModal] = useState(false);
  const [frequency, setFrequency] = useState();
  const [subRole, setSubRole] = useState([]);
  const dispatch = useDispatch();
  const storedSearchString = localStorage.getItem("searchedData1");
  const schedularFilter = localStorage.getItem("schedularFilterData1") || "{}";
  const schedularFilterData = JSON.parse(schedularFilter);
  // const [filterType, setFilterType] = React.useState(0);
  const [filterType, setFilterType] = React.useState("issueLog");
  const [escalationType, setEscalationType] = React.useState();

  const [searchQuery, setSearchQuery] = React.useState(
    storedSearchString || ""
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    if (!filterType) {
      setFilterType("issueLog");
    }
  }, [filterType]);

  // useEffect(() => {
  //   if (defaultStartDate && defaultEndDate) {
  //     setstart_date(defaultStartDate);
  //     setend_date(defaultEndDate);
  //   }
  // }, [filterType]);

  useEffect(() => {
    if (schedularFilterData) {
      console.log("schedularFilterData", schedularFilterData);
      setFilterType(schedularFilterData.type);
      setEscalationType(schedularFilterData?.escalationType);
      setstart_date(schedularFilterData.start_date);
      setend_date(schedularFilterData.end_date);
      setFrequency(schedularFilterData.frequency);
      setFilterStore(schedularFilterData.storeId);
      setFilterUser(schedularFilterData.userId);
    }
  }, [schedularFilter]);

  const handleDateClick = () => {
    const dates = {
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
    };
    setNewDate(dates);
    setShowSchedular(true);

    localStorage.setItem("storedDateData1", JSON.stringify(dates));
    localStorage.setItem("searchedData1", searchQuery);
  };

  useEffect(() => {
    if (!searchQuery) {
      const storedSearchString = localStorage.getItem("searchedData1");
      setSearchQuery(storedSearchString || "");
    }
  }, [searchQuery]);

  useEffect(() => {
    localStorage.setItem("searchedData1", searchQuery);
    props.setPage(1);
  }, [searchQuery]);

  useEffect(() => {
    if (Object.keys(newDate).length > 0) {
      setShowSchedular(false);
    }
  }, [newDate]);

  useEffect(() => {
    setSearchQuery(props.searchValue || "");
  }, [props.searchValue]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      const searchedData = JSON.stringify(searchQuery);
      localStorage.setItem("searchedData1", searchedData);
    } else if (event.keyCode === 8 && searchQuery === "") {
      localStorage.removeItem("searchedData1");
    }
  };

  useEffect(() => {
    const searchInput = searchInputRef.current;
    if (searchInput) {
      searchInput.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      if (searchInput) {
        searchInput.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, [searchQuery]);

  const modalopenFilterhandleClose = () => {
    setOpenFilterModal(false);
  };

  // code for pre selected store
  useEffect(() => {
    if (filterStore && typeof filterStore === 'string') {
      const sourceList = storeListBasedOnRole?.length ? storeListBasedOnRole : storeList;
        if (sourceList && sourceList.length) {
        const preselectedStore = sourceList.find(store => store._id === filterStore);
          if (preselectedStore) {
          setFilterStore(preselectedStore);
        }
      }
    }
  }, [filterStore, storeList, storeListBasedOnRole]);
  
  useEffect(() => {
    if (filterUser && typeof filterUser === 'string') {
      const userSourceList = userListBasedOnStore?.length ? userListBasedOnStore : userList;
        if (userSourceList && userSourceList.length) {
        const preselectedUser = userSourceList.find(user => user._id === filterUser);
          if (preselectedUser) {
          setFilterUser(preselectedUser);
        }
      }
    }
  }, [filterUser, userList, userListBasedOnStore]);
  

  
  const handleFilter = () => {
    handleDateClick();
    const filterObject = {
      type: filterType,
      storeId: filterStore?._id,
      userId: filterUser?._id,
      start_date: moment(start_date).format("YYYY-MM-DD"),
      end_date: moment(end_date).format("YYYY-MM-DD"),
      frequency: frequency,
      escalationType: escalationType,
      search: searchQuery,
      // pagination: {
      //   page: 1,
      //   per_page: 10,
      // },
    };

    localStorage.setItem("schedularFilterData1", JSON.stringify(filterObject));
    localStorage.setItem("searchedData1", searchQuery);
    props.handleFilter(filterObject);
    setOpenFilterModal(false);
  };

  const listUser = (data, key) => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `report/dropdown?company_id=${id}`;
    dispatch(ListUserChecklistApi(data, key));
  };

  useEffect(() => {
    if (filterStore) {
      const storeId = typeof filterStore === 'object' ? filterStore._id : filterStore;
      listUser(
        {
          body: {
            storeIds: [storeId], 
            userIds: [],
            start_date: start_date,
            end_date: end_date,
          },
        },
        "user"
      );
    }
  }, [filterStore, start_date, end_date]);
  

  const handleFreqChange = (e) => {
    setFrequency(e.target.value);
  };

  return (
    <>
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px" }}
        className="header-card"
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} lg={7} md={4} sm={12} alignItems="left">
            <Stack
              direction="row"
              spacing={1}
              alignItems="left"
              justifyContent={{ xs: "center", sm: "flex-start" }}
              sx={{
                marginRight: "1px",
                marginLeft: { xs: "0px", sm: "10px" },
              }}
            >
              <Stack direction="row" alignItems="left">
                <Button
                  className="header-add cancle-hover"
                  style={{
                    height: "42px",
                    borderRadius: "8px",
                    padding: "12px 16px",
                  }}
                  sx={{
                    width: { xs: "168px !important", sm: "auto !important" },
                  }}
                  onClick={(e) => setOpenFilterModal(true)}
                >
                  <FilterAltOutlinedIcon sx={{ mr: 1 }} />
                  Filter
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Modal
        open={openFilterModal}
        onClose={modalopenFilterhandleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableEscapeKeyDown={false}
      >
        <Box
          sx={{
            ...downloadAllstyle,
            p: 0,
            width: { xs: "90%", sm: "80%", md: "60%", lg: "40%" },
            margin: "0 auto",
          }}
        >
          <Box
            sx={{
              position: "sticky",
              right: "15px",
              top: "15px",
              cursor: "pointer",
              textAlign: "right",
              padding: "0px 10px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Box onClick={() => setOpenFilterModal(false)}>
              <CloseIcon />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                pt: 2,
                px: 4,
              }}
            >
              {/* <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Store</label>

                <Autocomplete
                  options={
                    storeListBasedOnRole && storeListBasedOnRole.length
                      ? storeListBasedOnRole
                      : storeList && storeList.length
                      ? storeList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select Store"
                      sx={{ borderRadius: "8px" }}
                    />
                  )}
                  value={filterStore}
                  onChange={(event, newValue) => {
                    setFilterStore(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value._id
                  }
                  renderOption={(props, option) => (
                    <li {...props} key={option._id}>
                      {option.name}
                    </li>
                  )}
                  clearable
                  disableCloseOnSelect={false}
                />
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>User</label>

                <Autocomplete
                  options={
                    storeListBasedLoader
                      ? []
                      : userListBasedOnStore && userListBasedOnStore.length
                      ? userListBasedOnStore
                      : userList && userList.length
                      ? userList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select User"
                      sx={{ borderRadius: "8px" }}
                    />
                  )}
                  value={filterUser || null}
                  loading={storeListBasedLoader}
                  loadingText="Users Loading..."
                  onChange={(event, newValue) => {
                    setFilterUser(newValue);
                  }}
                  onOpen={() => {
                    if (!userListBasedOnStore.length && !userList.length) {
                      setOptions(
                        userListBasedOnStore?.length
                          ? userListBasedOnStore
                          : userList
                      );
                    }
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option._id === value?._id
                  }
                  disableCloseOnSelect={false}
                  clearOnBlur={false}
                />
              </FormControl> */}
              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select Store</label>

                <Autocomplete
  options={
    storeListBasedOnRole && storeListBasedOnRole.length
      ? storeListBasedOnRole
      : storeList && storeList.length
      ? storeList
      : []
  }
  getOptionLabel={(option) => option?.name || ""}
  renderInput={(params) => (
    <TextField
      {...params}
      label=""
      size="small"
      placeholder="Select Store"
      sx={{ borderRadius: "8px" }}
    />
  )}
  value={filterStore || null}
  onChange={(event, newValue) => {
    setFilterStore(newValue);
  }}
                  isOptionEqualToValue={(option, value) =>
                    option?._id === value?._id
                  }
  disableCloseOnSelect={false}
  clearOnBlur={false}
/>
              </FormControl>

              <FormControl fullWidth sx={{ mb: 2 }}>
                <label>Select User</label>

                <Autocomplete
                  options={
                    storeListBasedLoader
                      ? []
                      : userListBasedOnStore && userListBasedOnStore.length
                      ? userListBasedOnStore
                      : userList && userList.length
                      ? userList
                      : []
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label=""
                      size="small"
                      placeholder="Select User"
                      sx={{ borderRadius: "8px" }}
                    />
                  )}
                  value={filterUser || null}
                  loading={storeListBasedLoader}
                  loadingText="Users Loading..."
                  onChange={(event, newValue) => {
                    setFilterUser(newValue);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option?._id === value?._id
                  }
                  disableCloseOnSelect={false}
                  clearOnBlur={false}
                />
              </FormControl>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 2,
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                }}
              >
                <FormControl fullWidth sx={{ mr: { xs: 0, sm: "16px" } }}>
                  <label>Select Module</label>
                  <Select
                    name="type"
                    value={filterType}
                    onChange={(e) => setFilterType(e.target.value)}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Select Job Type"
                  >
                    <MenuItem value="" disabled>
                      Select Module
                    </MenuItem>
                    {typeOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ mr: { xs: 0, sm: "16px" } }}>
                  <label>Select Escalation</label>
                  <Select
                    name="escalationType"
                    value={escalationType}
                    onChange={(e) => setEscalationType(e.target.value)}
                    size="small"
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "8px" }}
                    placeholder="Select Escalation"
                  >
                    <MenuItem value="" disabled>
                      Select Escalation
                    </MenuItem>
                    {escalationOptions.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {/* <FormControl fullWidth sx={{ ml: { xs: 0, sm: "16px" } }}>
                  <label>Frequency</label>
                  <Select
                    name="frequency"
                    sx={{
                      borderRadius: "8px",
                      textAlign: "left",
                    }}
                    value={frequency}
                    onChange={handleFreqChange}
                    helperText="select frequency"
                    size="small"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value={0}>All Frequency</MenuItem>
                    <MenuItem value={1}>No Frequency</MenuItem>
                    <MenuItem value={2}>Hourly</MenuItem>
                    <MenuItem value={3}>Daily</MenuItem>
                    <MenuItem value={4}>Weekly</MenuItem>
                    <MenuItem value={5}>Monthly</MenuItem>
                  </Select>
                </FormControl> */}
              </Box>

              <label>Date</label>
              <Box
                sx={{
                  mb: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: { xs: "nowrap", sm: "nowrap" },
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={start_date}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setstart_date(dateSort);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ mr: { xs: 1, sm: 1 } }} />
                    )}
                    className="date-pick date-pick-submision date-width"
                    maxDate={new Date()}
                  />
                </LocalizationProvider>
                <Typography className="date-to" sx={{ alignSelf: "center" }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    inputFormat="DD/MM/YYYY"
                    size="small"
                    value={end_date}
                    onChange={(newValue) => {
                      let dateSort = moment(newValue?.$d)
                        .format("YYYY-MM-DD")
                        .split("/")
                        .join("");
                      setend_date(dateSort);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} sx={{ ml: { xs: 1, sm: 1 } }} />
                    )}
                    className="date-pick date-pick-submision date-width"
                    maxDate={new Date()}
                    minDate={start_date}
                  />
                </LocalizationProvider>
              </Box>
              <Stack alignItems="left" sx={{ mb: 2 }}>
                <label>Search</label>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-start-adornment"
                  value={searchQuery}
                  placeholder="Search by  Category, Token"
                  onChange={(e) => {
                    setSearchQuery(e.target.value);
                    props.searchOnChange(e.target.value);
                    localStorage.setItem("searchedData1", e.target.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              position: "sticky",
              bottom: 0,
              width: "100%",
              gap: "30px",
              px: 3,
              pt: 1,
              pb: 2,
              background: "#fff",
            }}
          >
            <Button
              className="cancle-button cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
                background: "#fff",
              }}
              onClick={() => {
                setFilterType("issueLog");
                setEscalationType();
                setFilterStore([]);
                setFilterUser([]);
                setSearchQuery("");
                setFrequency();
                props.searchOnChange("");
                localStorage.setItem("searchedData1", "");
                const dates = {
                  start_date: moment(defaultStartDate).format("YYYY-MM-DD"),
                  end_date: moment(defaultEndDate).format("YYYY-MM-DD"),
                };
                setstart_date(dates.start_date);
                setend_date(dates.end_date);

                setNewDate(dates);
                setShowSchedular(true);
                localStorage.setItem("storedDateData1", JSON.stringify(dates));
              }}
            >
              Clear
            </Button>
            <Button
              className="header-add cancle-hover"
              style={{
                height: "46px",
                padding: "12px 16px",
                width: "47%",
              }}
              onClick={(e) => {
                handleFilter();
              }}
            >
              Apply
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default Header;
