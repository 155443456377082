/** @format */

import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Stack,
  Button,
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Avatar,
  Switch,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { styled } from "@mui/material/styles";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useDispatch } from "react-redux";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  PrivateRouteApi,
  UserPrivateApi,
} from "../../../redux/actions/LogInAction";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import Progressbar from "../../../GlobalProgress/Progressbar";
import {
  UserPermissionApi,
  UserPermissionSuccess,
} from "../../../redux/actions/User";
import { ThemeProvider } from "@mui/material/styles";
import MUIRichTextEditor from "mui-rte";

const UserPermission = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userName = location?.state?.data?.name;
  const navigate = useNavigate();
  const [userPermissionData, setUserPermissionData] = useState({});
  const [permissionSwitch, setPermissionSwitch] = useState({
    checklistenable: "",
    ManageChecklist: "",
    Managesubmissions: "",
    checklistform: "",
    lmsenable: "",
    managelmstraining: "",
    managelmsenable: "",
    managelmsassessment: "",
    managelmscertificate: "",
    issuesenable: "",
    chatenable: "",
    logEntryenable: "",
    manageLogEntry: "",
    attendanceenable: "",
    manageAttendance: "",
    boadcastMessage: "",
    issuesmanage: "",
    departmentenable: "",
    managedepartment: "",
    auditenable: "",
    wasteManagementenable: "",
    managewasteManagement: "",
    manageproduct: "",
    customerlogenable: "",
    managedatamanager: "",
    userenable: "",
    manageuser: "",
    manageuserpermission: "",
    roleenable: "",
    manageroles: "",
    storeenable: "",
    managestore: "",
    schedulerAccess: "",
    manageScheduler: "",
    mobileaccess: "",
  });

  const Loader = useSelector(
    (state) =>
      state && state?.MobileAccessData && state?.MobileAccessData?.loading
  );
  const permissionData = useSelector(
    (state) =>
      state?.logIn &&
      state?.logIn?.userPrivate &&
      state?.logIn?.userPrivate?.data &&
      state?.logIn?.userPrivate?.data?.data
  );
  const userPermissionRes = useSelector(
    (state) =>
      state?.user &&
      state?.user?.updatePermission &&
      state?.user?.updatePermission?.data &&
      state?.user?.updatePermission?.data?.data
  );

  useEffect(() => {
    setPermissionSwitch({
      checklistenable: userPermissionData?.checklist?.enable,
      ManageChecklist: userPermissionData?.checklist?.manageChecklist,
      Managesubmissions: userPermissionData?.checklist?.submissions,
      checklistform: userPermissionData?.checklist?.forms,
      lmsenable: userPermissionData?.lms?.enable,
      managelmstraining: userPermissionData?.lms?.manageLms,
      managelmsenable: userPermissionData?.lms?.lmsProgress,
      managelmsassessment: userPermissionData?.lms?.assgiments,
      managelmscertificate: userPermissionData?.lms?.certificate,
      issuesenable: userPermissionData?.issueLog?.enable,
      chatenable: userPermissionData?.chat?.enable,
      logEntryenable: userPermissionData?.logEntry?.enable,
      manageLogEntry: userPermissionData?.logEntry?.manageLogEntry,
      attendanceenable: userPermissionData?.attendance?.enable,
      manageAttendance: userPermissionData?.attendance?.manageAttendance,
      issuesmanage: userPermissionData?.issueLog?.manageIssues,
      boadcastMessage: userPermissionData?.boadcastMessage?.enable,
      departmentenable: userPermissionData?.department?.enable,
      managedepartment: userPermissionData?.department?.manageDepartment,
      auditenable: userPermissionData?.audit?.enable,
      wasteManagementenable: userPermissionData?.wastageLog?.enable,
      managewasteManagement: userPermissionData?.wastageLog?.manageWastageLog,
      manageproduct: userPermissionData?.wastageLog?.manageProduct,
      customerlogenable: userPermissionData?.customerLog?.enable,
      managedatamanager: userPermissionData?.addCompany?.dataManager,
      userenable: userPermissionData?.user?.enable,
      manageuser: userPermissionData?.user?.manageUsers,
      manageuserpermission: userPermissionData?.user?.managePermissions,
      roleenable: userPermissionData?.roles?.enable,
      manageroles: userPermissionData?.roles?.manageRole,
      storeenable: userPermissionData?.store?.enable,
      managestore: userPermissionData?.store?.manageStore,
      mobileaccess: userPermissionData?.mobileAccess?.enable,
      schedulerAccess: userPermissionData?.scheduler?.enable,
      manageScheduler: userPermissionData?.scheduler?.manageScheduler,
    });
  }, [userPermissionData]);

  useEffect(() => {
    if (permissionData) {
      setUserPermissionData(permissionData);
    }
  }, [permissionData]);

  useEffect(() => {
    UserPermissionSuccess("");
    const data = {};
    const id = localStorage.getItem("companyId");
    let userId = location?.state?.data?._id;
    data.url =
      BASE_URL +
      `useradminpermission/userPermissions?company_id=${id}&userId=${userId}`;
    dispatch(UserPrivateApi(data));
  }, []);

  // useEffect(() => {
  //   if (userPermissionRes !== undefined) {
  //     if (userPermissionRes?.user?.managePermissions === true) {
  //       localStorage.setItem(
  //         "permissionData",
  //         JSON.stringify(userPermissionRes)
  //       );
  //     }
  //     setTimeout(() => {
  //       navigate("/user-list");
  //     }, 1000);
  //   }
  // }, [userPermissionRes]);

  useEffect(() => {
    const saveAndRedirect = () => {
      if (userPermissionRes !== undefined) {
        if (userPermissionRes?.user?.managePermissions === true) {
          localStorage.setItem(
            "permissionData",
            JSON.stringify(userPermissionRes)
          );
        }
        setTimeout(() => {
          // Callback function for navigation
          navigate("/user-list");
          // window.location.href = "/user-list"; // Redirect to "/user-list" screen
        }, 1000);
      }
    };

    saveAndRedirect();
  }, [userPermissionRes]);

  const handleSwitch = (event) => {
    const value = event.target.checked;
    setPermissionSwitch({
      ...permissionSwitch,
      [event.target.name]: value,
    });
  };

  const listDetails = () => {
    const _id = localStorage.getItem("companyId");
    const userId = location?.state?.data?._id;
    let payloadData = {
      userId: userId,
      checklist: {
        enable: permissionSwitch?.checklistenable,
        manageChecklist: permissionSwitch?.ManageChecklist,
        submissions: permissionSwitch?.Managesubmissions,
        forms: permissionSwitch?.checklistform,
      },
      lms: {
        enable: permissionSwitch?.lmsenable,
        manageLms: permissionSwitch?.managelmstraining,
        lmsProgress: permissionSwitch?.managelmsenable,
        assgiments: permissionSwitch?.managelmsassessment,
        certificate: permissionSwitch?.managelmscertificate,
      },
      issueLog: {
        enable: permissionSwitch?.issuesenable,
        manageIssues: permissionSwitch?.issuesmanage,
      },
      boadcastMessage: {
        enable: permissionSwitch?.boadcastMessage,
      },
      department: {
        enable: permissionSwitch?.departmentenable,
        manageDepartment: permissionSwitch?.managedepartment,
      },
      audit: {
        enable: permissionSwitch?.auditenable,
      },
      wastageLog: {
        enable: permissionSwitch?.wasteManagementenable,
        manageWastageLog: permissionSwitch?.managewasteManagement,
        manageProduct: permissionSwitch?.manageproduct,
      },
      customerLog: {
        enable: permissionSwitch?.customerlogenable,
      },
      addCompany: {
        enable: true,
        dataManager: permissionSwitch?.managedatamanager,
      },
      user: {
        enable: permissionSwitch?.userenable,
        manageUsers: permissionSwitch?.manageuser,
        managePermissions: permissionSwitch?.manageuserpermission,
      },
      roles: {
        enable: permissionSwitch?.roleenable,
        manageRole: permissionSwitch?.manageroles,
      },
      store: {
        enable: permissionSwitch?.storeenable,
        manageStore: permissionSwitch?.managestore,
      },
      mobileAccess: {
        enable: permissionSwitch?.mobileaccess,
      },
      chat: {
        enable: permissionSwitch?.chatenable
      },
      attendance: {
        enable: permissionSwitch?.attendanceenable,
        manageAttendance: permissionSwitch?.manageAttendance,
      },
      scheduler: {
        enable: permissionSwitch?.schedulerAccess,
        manageScheduler: permissionSwitch?.manageScheduler
      },
      logEntry: {
        enable: permissionSwitch?.logEntryenable,
        manageLogEntry: permissionSwitch?.manageLogEntry
      }
      // boadcastMessage: {
      //   enable: true,
      // },
    };
    let newPayload = {
      body: payloadData,
    };
    newPayload.url =
      BASE_URL + `useradminpermission/updatePermissions?company_id=${_id}`;
    dispatch(UserPermissionApi(newPayload));
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      {Loader ? (
        <Progressbar />
      ) : (
        <>
          <Box className="header-card">
            <Grid container spacing={1}>
              <Grid
                item
                md={10}
                xs={10}
                sm={10}
                lg={10}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  variant="p"
                  component="b"
                  sx={{ padding: "5px", textAlign: "left" }}
                >
                  User : {userName}
                </Typography>
              </Grid>
              <Grid
                item
                md={2}
                xs={2}
                sm={2}
                lg={2}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "end",
                }}
              >
                <Link to="/user-list" className="submenu-item">
                  <Button
                    variant="outlined"
                    className="cancle-button cancle-hover"
                    sx={{ mr: 2 }}
                  >
                    Cancel
                  </Button>
                </Link>
                <Button
                  variant="outlined"
                  type="submit"
                  onClick={listDetails}
                  className="done-btn cancle-hover"
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Box>
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "80vh",
              mt: 1,
              p: 1,
              overflowX: "hidden",
              overflowY: "scroll",
              borderRadius: "8px",
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: "8px",
              },
            }}
          >
            <Card className="user-information" sx={{ mb: 2 }}>
              <Grid
                container
                spacing={0}
                sx={{ mt: 4, justifyContent: "center" }}
              >
                <Grid item md={6} lg={6} xs={12} sm={12}>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Checklist Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Checklist Enable/Disable:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={permissionSwitch.checklistenable}
                              name="checklistenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            ManageForm:
                          </TableCell>
                          <TableCell className="list-key-value">
                            <Switch
                              checked={permissionSwitch.checklistform}
                              name="checklistform"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            ManageChecklist:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.ManageChecklist}
                              name="ManageChecklist"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Managesubmissions:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.Managesubmissions}
                              name="Managesubmissions"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      LMS Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            LMS Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.lmsenable}
                              name="lmsenable"
                              color={"success"}
                              value={userPermissionData?.checklist?.enable}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Lms Progress:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managelmsenable}
                              name="managelmsenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Lms Training:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managelmstraining}
                              name="managelmstraining"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Assessments:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managelmsassessment}
                              name="managelmsassessment"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage certificate:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managelmscertificate}
                              name="managelmscertificate"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Issues Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Issues Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.issuesenable}
                              name="issuesenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Issues:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.issuesmanage}
                              name="issuesmanage"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Chat Module
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Chat Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.chatenable}
                              name="chatenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Log Entry Module
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Log Entry Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.logEntryenable}
                              name="logEntryenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Log Entry:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.manageLogEntry}
                              name="manageLogEntry"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Attendance Module
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Attendance Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.attendanceenable}
                              name="attendanceenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Attendance:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.manageAttendance}
                              name="manageAttendance"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>


                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Broadcast Messages
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Broadcast Message Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.boadcastMessage}
                              name="boadcastMessage"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Audit Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Audit Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.auditenable}
                              name="auditenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      WasteManagement Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            WasteManagement Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.wasteManagementenable}
                              name="wasteManagementenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage WastageLog:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managewasteManagement}
                              name="managewasteManagement"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Product:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.manageproduct}
                              name="manageproduct"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      User Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            User Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.userenable}
                              name="userenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Users:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.manageuser}
                              name="manageuser"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Users Permission:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.manageuserpermission}
                              name="manageuserpermission"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Store Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Store Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.storeenable}
                              name="storeenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Store:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managestore}
                              name="managestore"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Roles Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Roles Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.roleenable}
                              name="roleenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Roles:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.manageroles}
                              name="manageroles"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Create Company
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Data Manager:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managedatamanager}
                              name="managedatamanager"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Department Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Department Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.departmentenable}
                              name="departmentenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Department:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.managedepartment}
                              name="managedepartment"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Customer Logs Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Customer Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.customerlogenable}
                              name="customerlogenable"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                  
                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Scheduler Module
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Scheduler Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.schedulerAccess}
                              name="schedulerAccess"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell className="list-value">
                            Manage Scheduler:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.manageScheduler}
                              name="manageScheduler"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>

                  <Grid container spacing={1}>
                    <Typography
                      variant="p"
                      component="b"
                      sx={{ padding: "5px" }}
                    >
                      Mobile Access Modules
                    </Typography>
                    <Table aria-label="simple table" className="details-table">
                      <TableBody>
                        <TableRow>
                          <TableCell className="list-value">
                            Mobile Access Enable/Disable:
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={permissionSwitch.mobileaccess}
                              name="mobileaccess"
                              color={"success"}
                              onChange={handleSwitch}
                            />
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Grid>
            </Card>
          </Box>
        </>
      )}
    </Box>
  );
};

export default UserPermission;
