/** @format */

import {
  Box,
  Grid,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  LinearProgress,
  TextField,
  Button,
  Divider,
  Tooltip,
} from "@mui/material";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import { useState } from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useEffect } from "react";
import BookmarksIcon from "@mui/icons-material/Bookmarks";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CheckIcon from "@mui/icons-material/Check";
import RepeatIcon from "@mui/icons-material/Repeat";
import ReactPlayer from "react-player";
import {
  TrainingDetailsApi,
  TrainingDetailsApi1,
} from "../../../../redux/actions/LmsAction";
import { BASE_URL } from "../../../../redux/apiConfig/BaseUrl";
import { useDispatch } from "react-redux";
import { AuditorContext } from "../../../../../context/auditorContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import ProgressbarTransparent from "../../../../GlobalProgress/ProgressbarTransparent";
import SubmitAssessment from "../../MyAssessments/SubmitAssessment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";
import { confirmAlert } from "react-confirm-alert";
import DocViewer, { DocViewerRenderers, PDFRenderer } from "react-doc-viewer";
import { getFileType } from "../../../../../utils/util";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import PublishOutlinedIcon from "@mui/icons-material/PublishOutlined";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";

const ImageStepComponent = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const allTrainingData = location?.state?.allTrainingData
    ? location?.state?.allTrainingData
    : location?.state;
  const from = location?.state?.from;
  const selectedAssessment = localStorage.getItem("selectedAssessment");

  const navigate = useNavigate();
  const [allTrainingDataStage, setAllTrainingData] = useState(allTrainingData);
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedStep, setSelectedStep] = useState("");
  const [selectedStepId, setSelectedStepId] = useState(1);
  const [enableSubmitButtn, setEnableSubmitButtn] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredSteps, setFilteredSteps] = useState([]);
  const [isLeftSideOpen, setIsLeftSideOpen] = useState(true);
  const [ProgressData, setProgressData] = useState([]);
  const [assessmentMode, setAssessmentMode] = useState(false);
  const [nextAssessment, setNextAssessment] = useState("");
  const [updatedValue, setupdatedValue] = useState(allTrainingData);

  useEffect(() => {
    setAssessmentMode(from === "DESCLAIMER" ? true : false);
  }, [from]);

  let {
    storeId,
    storeTitle,
    companyId,
    setcurrentAssessment,
    currentAssessment,
  } = useContext(AuditorContext);
  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  const allTrainingDataReducer = useSelector(
    (state) =>
      state.lms &&
      state.lms.detailsTrainee1 &&
      state.lms.detailsTrainee1.data &&
      state.lms.detailsTrainee1.data.data
  );
  const allTrainingProgress = useSelector(
    (state) =>
      state?.lms &&
      state?.lms?.trDetailsVideoTypeData &&
      state?.lms?.trDetailsVideoTypeData?.data &&
      state?.lms?.trDetailsVideoTypeData?.data?.data
  );
  const Loader = useSelector(
    (state) => state && state.lms && state.lms.loading
  );
  useEffect(() => {
    setProgressData(allTrainingDataReducer);
    if (allTrainingDataReducer) {
      console.log("allTrainingDataReducer", allTrainingDataReducer);
      setAllTrainingData(allTrainingDataReducer);
    }
  }, [allTrainingDataReducer]);

  useEffect(() => {
    const storedSelectedStep = localStorage.getItem("selectedStep");
    if (storedSelectedStep) {
      setSelectedStep(storedSelectedStep);
    }
  }, []);

  useEffect(() => {
    if (filteredSteps?.length > 0 && selectedStep === "") {
      setSelectedStep(filteredSteps[0]?.content?.url);
    } else if (filteredSteps?.length === 0) {
      setSelectedStep("");
    }
  }, [filteredSteps, selectedStep]);

  useEffect(() => {
    localStorage.setItem("selectedStep", selectedStep);
  }, [selectedStep]);

  useEffect(() => {
    if (!selectedAssessment) {
      var getIndex = allTrainingProgress?.assessment
        ?.map((item, i) => {
          if (item._id === currentAssessment._id) {
            var j = i;
            while (allTrainingProgress?.assessment[j]?.isTaken) {
              j++;
            }
            return i === j ? -1 : j;
          }
        })
        .filter((item) => item);
      if (
        getIndex[0] < 0 ||
        getIndex[0] >= allTrainingProgress?.assessment?.length
      ) {
        getIndex = [
          allTrainingProgress?.assessment?.findIndex((item) => !item.isTaken),
        ];
      }
      if (getIndex[0] < 0) {
        navigate("/auditor-home/my-assessments", {
          state: {},
        });
      }
      setNextAssessment(allTrainingProgress?.assessment[getIndex[0]]);
      document.getElementById("training-module").scrollIntoView();
    }
  }, [selectedAssessment]);

  useEffect(() => {
    const filtered = allTrainingData?.steps?.filter((step) =>
      step?.name?.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredSteps(filtered);
  }, [searchText, allTrainingData?.steps]);

  useEffect(() => {
    const trainingId = localStorage.getItem("trainingId");
    if (storeId === allTrainingDataStage?.store?._id) {
      if (trainingId === allTrainingDataStage.trainingId) {
        const filtered = allTrainingDataStage?.steps?.filter((step) =>
          step?.name?.toLowerCase().includes(searchText.toLowerCase())
        );
        setFilteredSteps(filtered);
      }
    }
  }, [searchText, allTrainingDataStage?.steps]);

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevIsAccordionOpen) => !prevIsAccordionOpen);
  };

  useEffect(() => {
    if (selectedStepId && updatedValue) {
      const selectedStep = updatedValue?.steps?.find(
        (step) => step?.id === selectedStepId
      );
      if (selectedStep) {
        setEnableSubmitButtn(!selectedStep.finished);
      }
    }
  }, [selectedStepId, allTrainingData]);

  useEffect(() => {
    if (
      storeId === allTrainingDataStage?.store?._id &&
      localStorage.getItem("trainingId") === allTrainingDataStage.trainingId
    ) {
      setupdatedValue(allTrainingDataStage);
    }
  }, [allTrainingDataStage]);

  const getConfirmationClose = () => {
    const attempt = localStorage.getItem("attempt");
    const currentAttempt = localStorage.getItem("currentAttempt");
    const repeatedAssessment = localStorage.getItem("repeatedAssessment");

    const message = !assessmentMode
      ? "Do you want to cancel training chapters submissions ?"
      : repeatedAssessment === "false"
      ? "Do you want to cancel assessment submission?"
      : `Do you want to cancel assessment submission for remaining ${
          attempt - currentAttempt
        } users?`;

    confirmAlert({
      title: "Exit",
      message: message,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            setTimeout(() => {
              navigate("/auditor-home/my-trainings", {
                state: {},
              });
            }, 500);
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  const handleBackButtonClick = () => {
    getConfirmationClose();
  };

  const handleButtonClick = (id) => {
    const selectedStep = allTrainingData?.steps?.find(
      (step) => step?.id === id
    );
    setSelectedStep(selectedStep?.content?.url);
    if (selectedStep?.type === 5) {
    }
    setSelectedStepId(id);
    setAssessmentMode(false);
  };

  const handleSubmitChapter = (id) => {
    // sequence order submission started
    const currentChapterIndex = updatedValue?.steps.findIndex(
      (step) => step.id === id
    );
    if (currentChapterIndex > 0) {
      const previousChapters = updatedValue?.steps.slice(
        0,
        currentChapterIndex
      );
      const isAllPreviousChaptersCompleted = previousChapters.every(
        (chapter) => chapter.finished
      );
      if (!isAllPreviousChaptersCompleted) {
        const pendingChapters = previousChapters
          .filter((chapter) => !chapter.finished)
          .map((chapter) => chapter.name)
          .join(", ");

        toast.warning(
          `First conclude the previous chapter(s): ${pendingChapters}`
        );
        return;
      }
    }
    // sequence order submission ended

    if (companyId && storeId && storeTitle && selectedStepId) {
      const handleConcludeChapter = (chapterId) => {
        // Find the chapter to conclude and update its finished flag
        const updatedSteps = allTrainingData.steps.map((step) =>
          step.id === chapterId ? { ...step, finished: true } : step
        );
        setAllTrainingData({ ...allTrainingData, steps: updatedSteps });

        // Disable the Conclude Chapter button for the concluded chapter
        const updatedFilteredSteps = filteredSteps.map((step) =>
          step.id === chapterId ? { ...step, finished: true } : step
        );
        setFilteredSteps(updatedFilteredSteps);
      };

      const stepIds = localStorage.getItem("totalSteps");
      const totalSteps = stepIds
        ?.split(",")
        ?.map((id) => parseInt(id.trim(), 10));
      const trainingId = localStorage.getItem("trainingId");

      const payload = {
        body: {
          trainingId: trainingId,
          store: {
            _id: storeId,
            title: storeTitle,
          },
          stepIds: [selectedStepId],
        },
      };
      payload.url = BASE_URL + `trainingAssignment?company_id=${companyId}`;
      dispatch(TrainingDetailsApi1(payload));
    }
  };

  const handleAssessmentClick = (item) => {
    const trainingId = localStorage.getItem("trainingId");
    const trainingObj = {
      _id:
        allTrainingData?._id ||
        allTrainingDataStage.trainingId ||
        allTrainingDataStage._id,
      title: allTrainingData?.name || allTrainingDataStage.name,
      repeated: allTrainingData?.repeated || allTrainingDataStage.repeated,
    };
    item.training = trainingObj;
    localStorage.setItem("selectedAssessment", JSON.stringify(item));
    setcurrentAssessment(item);
    setAssessmentMode(true);
    navigate("/auditor-home/my-assessments/disclaimer-page", {
      state: { data: item, from: "training", allTrainingData: allTrainingData },
    });
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchText(value);
  };

  const handleToggleLeftSide = () => {
    setIsLeftSideOpen(!isLeftSideOpen);
  };
  const downloadFile = (url, filename, fileType) => {
    toast.success("Preparing file, downloading could take some time to start.");
    if (fileType == "PDF") {
      window.open(url, "_blank");
    } else {
      window.location.href = url;
    }
  };

  // No validation on prev next and not on left side(if any client don't want validation)
  // const handleChapterNavigation = (index) => {
  //   if (index >= 0 && index < filteredSteps.length) {
  //     setSelectedStep(filteredSteps[index].content?.url);
  //     setSelectedStepId(filteredSteps[index].id);
  //   }
  // };

  // Here validation is added for previous and next button if don't want uncommnent above code and comment below code
  const handleChapterNavigation = (index) => {
    if (index >= 0 && index < filteredSteps.length) {
      const selectedStep = filteredSteps[index];
      const currentStepIndex = filteredSteps.findIndex(
        (step) => step.id === selectedStepId
      );
      const isCurrentChapterFinished =
        filteredSteps[currentStepIndex]?.finished;

      if (index < currentStepIndex) {
        const isPreviousChapterFinished =
          currentStepIndex === 0 ||
          filteredSteps[currentStepIndex - 1]?.finished;

        if (!isPreviousChapterFinished) {
          const firstUnfinishedPreviousChapterIndex = filteredSteps.findIndex(
            (step, i) => !step.finished && i < currentStepIndex
          );
          toast.error(
            `Before moving ahead, first conclude the previous chapter "${filteredSteps[firstUnfinishedPreviousChapterIndex]?.name}".`,
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        } else {
          setSelectedStep(selectedStep.content?.url);
          setSelectedStepId(selectedStep.id);
        }
      } else if (!isCurrentChapterFinished) {
        toast.error(
          `First conclude the current chapter "${filteredSteps[currentStepIndex]?.name}" before moving to the next.`,
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      } else {
        setSelectedStep(selectedStep.content?.url);
        setSelectedStepId(selectedStep.id);
      }
    }
  };

  // Left side chapters validation added from here and bit in jsx
  const shouldShowBackValidation = (currentStepId) => {
    const currentIndex = filteredSteps.findIndex(
      (step) => step.id === currentStepId
    );
    if (currentIndex === 0) return false;
    const previousStep = filteredSteps[currentIndex - 1];
    return !previousStep.finished;
  };

  const shouldShowAheadValidation = (currentStepId) => {
    const currentIndex = filteredSteps.findIndex(
      (step) => step.id === currentStepId
    );
    const currentStep = filteredSteps[currentIndex];
    if (currentStep.finished) return false;
    const nextStep = filteredSteps[currentIndex + 1];
    return !nextStep || !currentStep.finished;
  };

  return (
    <Box id="training-module">
      <ToastContainer autoClose={3000} />
      <Box sx={{ marginTop: 2, pl: 1, pr: 1 }} className="header-card">
        <Accordion
          expanded={isAccordionOpen}
          className="custom-accordion"
          sx={{
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
            "& .MuiAccordionSummary-content": {
              margin: 0,
              alignItems: "center",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon onClick={handleAccordionToggle} />}
            sx={{ justifyContent: "space-between" }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  position: "absolute",
                  left: "0px",
                }}
              >
                <Box className="header-cardNew">
                  <Grid container alignItems="center">
                    <Grid item xs={2}>
                      <IconButton
                        onClick={handleBackButtonClick}
                        sx={{
                          fontSize: "1.25rem",
                        }}
                      >
                        <ArrowBackIcon /> BACK
                      </IconButton>
                    </Grid>
                    <Grid item xs={10}></Grid>
                  </Grid>
                </Box>
              </div>
              <Box
                onClick={handleAccordionToggle}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div></div>
                <Typography align="center" variant="h6" component="div">
                  <strong>
                    {isAccordionOpen
                      ? allTrainingData?.name
                      : allTrainingData?.name}
                  </strong>
                </Typography>

                <div>
                  <Typography align="center">
                    <strong>{storeTitle}</strong>
                  </Typography>
                </div>
              </Box>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                border: "1px solid",
                borderRadius: "4px",
                padding: "10px",
                background: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Name:</strong> {allTrainingData?.name}
                  </p>
                </Typography>

                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Summary:</strong> {allTrainingData?.summary}
                  </p>
                </Typography>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  {allTrainingData?.duration ? (
                    <div style={{ marginLeft: "1px", textAlign: "center" }}>
                      <AccessTimeIcon />
                      <span>
                        <strong>Duration:</strong>
                      </span>
                      {allTrainingData?.duration}
                    </div>
                  ) : (
                    <div>
                      {allTrainingData?.duration ? (
                        <span>
                          <strong>Duration:</strong> {allTrainingData?.duration}
                        </span>
                      ) : (
                        <span>
                          <strong>Duration:</strong> 0
                        </span>
                      )}
                    </div>
                  )}
                </Typography>

                <Typography sx={{ mt: 1 }}>
                  {allTrainingData?.updatedAt ? (
                    <React.Fragment>
                      <span>
                        <strong>Last updated:</strong>{" "}
                      </span>
                      {moment(allTrainingData?.updatedAt).format(
                        "YYYY/MM/DD HH:mm:ss"
                      ) || <span>"N/A"</span>}
                    </React.Fragment>
                  ) : (
                    // <React.Fragment>
                    //   <span>
                    //     <strong>Last updated:</strong>{" "}
                    //   </span>
                    //   {(() => {
                    //     const now = new Date();
                    //     const updatedAt = new Date(allTrainingData?.updatedAt);
                    //     const diffInMs = now - updatedAt;

                    //     const seconds = Math.floor(diffInMs / 1000);
                    //     const minutes = Math.floor(seconds / 60);
                    //     const hours = Math.floor(minutes / 60);
                    //     const days = Math.floor(hours / 24);
                    //     const months = Math.floor(days / 30);
                    //     const years = Math.floor(months / 12);

                    //     if (years > 0) {
                    //       return <span>{years} year(s) ago</span>;
                    //     } else if (months > 0) {
                    //       return <span>{months} month(s) ago</span>;
                    //     } else if (days > 0) {
                    //       return <span>{days} day(s) ago</span>;
                    //     } else {
                    //       return <span>{hours} hour(s) ago</span>;
                    //     }
                    //   })()}
                    // </React.Fragment>
                    <span>
                      <strong>Last updated:</strong> N/A
                    </span>
                  )}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Completion Progress:</strong>(
                    {allTrainingDataStage?.completedPercentage?.toFixed(
                      allTrainingDataStage?.completedPercentage ? 2 : 0
                    )}
                    %)
                  </p>
                </Typography>
                <div style={{ width: "250px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={allTrainingDataStage?.completedPercentage}
                    sx={{ height: 10 }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${allTrainingDataStage?.completedPercentage}%`,
                        backgroundColor:
                          allTrainingDataStage?.completedPercentage === 100
                            ? "green"
                            : "red",
                      }}
                    />
                  </LinearProgress>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Assessment Progress: </strong>(
                    {allTrainingData?.assessmentProgress?.toFixed(
                      allTrainingData?.assessmentProgress ? 2 : 0
                    )}
                    %)
                  </p>
                </Typography>
                <div style={{ width: "250px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={allTrainingData?.assessmentProgress}
                    sx={{ height: 10 }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${allTrainingData?.assessmentProgress}%`,
                        backgroundColor:
                          allTrainingData?.assessmentProgress === 100
                            ? "green"
                            : "red",
                      }}
                    />
                  </LinearProgress>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Assessment Status:</strong>{" "}
                    {"(" +
                      allTrainingData?.assessmentProgress?.toFixed(
                        allTrainingData?.assessmentProgress ? 2 : 0
                      ) +
                      "%)"}
                  </p>
                </Typography>

                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      marginRight: "16%",
                      background:
                        allTrainingData?.assessmentProgress >= 66
                          ? "green"
                          : allTrainingData?.assessmentProgress >= 33
                          ? "gray"
                          : "red",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {allTrainingData?.assessmentProgress}%
                  </div>
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
      </Box>

      {/* main content  */}
      <Grid container>
        {/* Left Side */}
        <Grid item xs={12} md={3} height={"100%"}>
          <Box
            height="100%"
            bgcolor="#f5f5f5"
            textAlign="center"
            style={
              isLeftSideOpen
                ? { height: "660px", overflow: "auto" }
                : {
                    height: "40px",
                    position: "absolute",
                    background: "transparent",
                    boxShadow:
                      "0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)",
                  }
            }
          >
            {isLeftSideOpen ? (
              <div sx={{ padding: "10px", textAlign: "left", marginLeft: 0 }}>
                {/* Toggle Button */}
                <Box sx={{ background: "#1f4b66" }}>
                  <IconButton
                    onClick={handleToggleLeftSide}
                    sx={{ marginLeft: "-25px", color: "white" }}
                  >
                    <ChevronLeftIcon />
                    <Typography
                      variant="body2"
                      sx={{
                        lineHeight: "1.2",
                        marginLeft: "4px",
                        color: "white",
                      }}
                    >
                      Hide Chapters
                    </Typography>
                  </IconButton>
                </Box>

                {/* Chapter */}
                <Typography
                  variant="h6"
                  component="div"
                  sx={{
                    m: 2,
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  <BookmarksIcon sx={{ marginRight: "5px" }} />
                  <Box
                    component="span"
                    sx={{ marginLeft: "2px", marginBottom: "2px" }}
                  >
                    Chapter
                  </Box>
                </Typography>

                <TextField
                  label="Search"
                  variant="outlined"
                  size="small"
                  style={{
                    paddingTop: 2,
                    width: "90%",
                  }}
                  value={searchText}
                  onChange={handleSearchChange}
                />

                {/* left side all the chapters showing from here without validation */}
                {/* {filteredSteps &&
                  filteredSteps.map((step, index) => (
                    <div key={step.id} style={{ marginTop: "10px" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleButtonClick(step.id)}
                        style={{
                          backgroundColor:
                            selectedStepId === step.id
                              ? "#1f4b66"
                              : "transparent",
                          width: "90%",
                          color:
                            selectedStepId === step.id ? "#FFFFFF" : "#1f4b66",
                        }}
                        sx={{
                          borderColor: "#1f4b66",
                          justifyContent: "space-between",
                        }}
                      >
                        <span>
                          {index + 1}. {step.name}
                          {step.finished && (
                            <CheckIcon style={{ color: "#1f4b66" }} />
                          )}
                        </span>
                      </Button>
                    </div>
                  ))} */}

                {/* with left side validation  */}
                {filteredSteps &&
                  filteredSteps.map((step, index) => (
                    <div key={step.id} style={{ marginTop: "10px" }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleButtonClick(step.id)}
                        style={{
                          backgroundColor:
                            selectedStepId === step.id
                              ? "#1f4b66"
                              : "transparent",
                          width: "90%",
                          color:
                            selectedStepId === step.id ? "#FFFFFF" : "#1f4b66",
                        }}
                        sx={{
                          borderColor: "#1f4b66",
                          justifyContent: "space-between",
                        }}
                        disabled={
                          shouldShowBackValidation(step.id) ||
                          shouldShowAheadValidation(step.id)
                        }
                      >
                        <span>
                          {index + 1}. {step.name}
                          {step.finished && (
                            <CheckIcon
                              style={{
                                color:
                                  selectedStepId === step.id
                                    ? "#FFFFFF"
                                    : "#1f4b66",
                              }}
                            />
                          )}
                        </span>
                      </Button>
                    </div>
                  ))}

                {allTrainingData?.assessment &&
                allTrainingData?.assessment?.length &&
                allTrainingData?.completedPercentage &&
                Number(allTrainingData?.completedPercentage) === 100 ? (
                  <Box mt={2} mb={2}>
                    <Typography
                      component="div"
                      sx={{
                        m: 2,
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <BookmarksIcon sx={{ marginRight: "5px" }} />
                      <Box
                        component="span"
                        sx={{ marginLeft: "2px", marginBottom: "2px" }}
                      >
                        Assessment
                      </Box>
                    </Typography>
                    {allTrainingProgress?.assessment?.length > 0 ? (
                      allTrainingProgress?.assessment?.map((assessment) => (
                        <div
                          key={assessment?._id}
                          style={{ marginTop: "10px" }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              assessment?.isTaken
                                ? ""
                                : handleAssessmentClick(assessment)
                            }
                            style={{
                              backgroundColor: "transparent",
                              width: "90%",
                            }}
                            sx={{ borderColor: "#1f4b66", color: "#1f4b66" }}
                          >
                            {assessment?.title}
                            {allTrainingData.repeated && (
                              <span>
                                {"\u00A0"}(
                                <RepeatIcon
                                  style={{ color: "#214b67", fontSize: "14px" }}
                                />
                                ){" "}
                              </span>
                            )}
                            {assessment?.isTaken && (
                              <CheckIcon style={{ color: "green" }} />
                            )}
                          </Button>
                        </div>
                      ))
                    ) : (
                      <div style={{ marginTop: "10px" }}>
                        No assessment found!
                      </div>
                    )}
                  </Box>
                ) : storeId === allTrainingDataStage?.store?._id &&
                  localStorage.getItem("trainingId") ===
                    allTrainingDataStage.trainingId &&
                  allTrainingDataStage.completedPercentage &&
                  Number(allTrainingDataStage.completedPercentage) === 100 ? (
                  <Box mt={2} mb={2}>
                    <Typography
                      // variant="h6"
                      component="div"
                      sx={{
                        m: 2,
                        display: "flex",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <BookmarksIcon sx={{ marginRight: "5px" }} />
                      <Box
                        component="span"
                        sx={{ marginLeft: "2px", marginBottom: "2px" }}
                      >
                        Assessment
                      </Box>
                    </Typography>
                    {allTrainingProgress?.assessment &&
                    allTrainingProgress?.assessment?.length > 0 ? (
                      allTrainingProgress?.assessment?.map((assessment) => (
                        <div
                          key={assessment?._id}
                          style={{ marginTop: "10px" }}
                        >
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                              assessment?.isTaken
                                ? ""
                                : handleAssessmentClick(assessment)
                            }
                            style={{
                              backgroundColor: "transparent",
                              width: "90%",
                            }}
                            sx={{ borderColor: "#1f4b66", color: "#1f4b66" }}
                          >
                            {assessment?.title}
                            {allTrainingData.repeated && (
                              <span>
                                {"\u00A0"}(
                                <RepeatIcon
                                  style={{ color: "#214b67", fontSize: "14px" }}
                                />
                                ){" "}
                              </span>
                            )}
                            {assessment?.isTaken && (
                              <CheckIcon style={{ color: "green" }} />
                            )}
                          </Button>
                        </div>
                      ))
                    ) : (
                      <div style={{ marginTop: "10px" }}>
                        No assessment found!
                      </div>
                    )}
                  </Box>
                ) : null}
                {/* </Link> */}
              </div>
            ) : (
              <Box sx={{ background: "#1f4b66" }}>
                <IconButton
                  onClick={handleToggleLeftSide}
                  sx={{ marginLeft: "5px", color: "white" }}
                >
                  <ChevronRightIcon />
                  <Typography
                    variant="body2"
                    sx={{
                      lineHeight: "1.2",
                      marginLeft: "4px",
                      color: "white",
                    }}
                  >
                    Show Chapters
                  </Typography>
                </IconButton>
              </Box>
            )}
          </Box>
        </Grid>

        {/* Right Side */}
        <Grid item xs={12} md={isLeftSideOpen ? 9 : 12}>
          {Loader && <ProgressbarTransparent play />}
          <Box
            height="100%"
            bgcolor="white"
            padding="3px"
            style={{ height: "660px", overflow: "auto" }}
          >
            <Box>
              {!assessmentMode &&
                allTrainingData?.steps &&
                allTrainingData.steps.map((step, index) => {
                  if (selectedStep === "" && index === 0) {
                    setSelectedStep(step?.content?.url);
                    setSelectedStepId(step?.id);
                  }
                  if (step.id === selectedStepId) {
                    return (
                      <>
                        <div
                          key={index}
                          style={{ textAlign: "center", padding: "20px" }}
                        >
                          <div className="training-title">
                            <Typography variant="h6" component="div">
                              <strong>{step?.name}</strong>
                            </Typography>
                            <Divider sx={{ mt: 1 }} />

                            <Typography
                              variant="body1"
                              component="div"
                              sx={{ pt: 1 }}
                            >
                              <span>
                                <strong>Summary: </strong> {step?.summary}
                              </span>
                            </Typography>
                          </div>
                          <Divider sx={{ mt: 1 }} />
                          <div>
                            {step?.type === 1 && (
                              <div
                                style={{ textAlign: "left", padding: "20px" }}
                              >
                                <strong>Content:</strong>
                                {step?.content && (
                                  <div
                                    style={{
                                      height: "calc(100% - 80px)",
                                      overflowY: "auto",
                                    }}
                                  >
                                    <Typography
                                      variant="body1"
                                      component="span"
                                      sx={{ mt: 1 }}
                                    >
                                      <ReactQuill
                                        value={step?.content.replace(/\\/g, "")}
                                        readOnly={true}
                                        theme={"bubble"}
                                        className="quill-training"
                                      />
                                    </Typography>
                                  </div>
                                )}

                                {/* Previous and Next Buttons */}
                                <div
                                  style={{
                                    width: isLeftSideOpen ? "62%" : "85%",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    textAlign: "center",
                                    marginTop: "20px",
                                    backgroundColor: "#f7f7f7",
                                    padding: "20px",
                                    position: "fixed",
                                    bottom: 0,
                                  }}
                                >
                                  <Tooltip
                                    title={filteredSteps[index - 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index - 1)
                                        }
                                        disabled={index === 0}
                                      >
                                        <ArrowBackIcon />
                                        Previous
                                      </Button>
                                    </span>
                                  </Tooltip>

                                  <Box m={0}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleSubmitChapter(selectedStepId)
                                      }
                                      disabled={
                                        !enableSubmitButtn || step.finished
                                      }
                                      sx={{
                                        borderColor: "#1f4b66",
                                        color: "#1f4b66",
                                      }}
                                    >
                                      {/* Conclude Chapter */}
                                      Conclude Chapter
                                      <PublishOutlinedIcon />
                                    </Button>
                                  </Box>

                                  <Tooltip
                                    title={filteredSteps[index + 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index + 1)
                                        }
                                        disabled={
                                          index === filteredSteps.length - 1
                                        }
                                      >
                                        Next
                                        <ArrowForwardIcon />
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            )}

                            {step?.type === 2 && (
                              <div style={{ position: "relative" }}>
                                <ReactPlayer
                                  url={step?.content?.url}
                                  controls
                                  width="100%"
                                  height="85%"
                                  style={{
                                    maxWidth: "85%",
                                    maxHeight: "85%",
                                  }}
                                />

                                {/* Buttons */}
                                <div
                                  style={{
                                    width: isLeftSideOpen ? "65%" : "88%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginTop: "10px",
                                    padding: "20px",
                                    position: "fixed",
                                    marginBottom: 1,
                                    bottom: 0,
                                    backgroundColor: "#f7f7f7",
                                  }}
                                >
                                  <Tooltip
                                    title={filteredSteps[index - 1]?.name || ""}
                                  >
                                    <span style={{ marginRight: "10px" }}>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index - 1)
                                        }
                                        disabled={index === 0}
                                        sx={{
                                          borderColor: "#1f4b66",
                                          color: "#1f4b66",
                                          "& .MuiSvgIcon-root": {
                                            fill: "#1f4b66",
                                          },
                                        }}
                                      >
                                        <ArrowBackIcon />
                                        Previous
                                      </Button>
                                    </span>
                                  </Tooltip>

                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      handleSubmitChapter(selectedStepId)
                                    }
                                    disabled={
                                      !enableSubmitButtn || step.finished
                                    }
                                    sx={{
                                      borderColor: "#1f4b66",
                                      color: "#1f4b66",
                                      margin: "0 auto",
                                      "& .MuiSvgIcon-root": {
                                        fill: "#1f4b66",
                                      },
                                    }}
                                  >
                                    Conclude Chapter
                                    <PublishOutlinedIcon />
                                  </Button>

                                  <Tooltip
                                    title={filteredSteps[index + 1]?.name || ""}
                                  >
                                    <span style={{ marginLeft: "10px" }}>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index + 1)
                                        }
                                        disabled={
                                          index === filteredSteps.length - 1
                                        }
                                        sx={{
                                          borderColor: "#1f4b66",
                                          color: "#1f4b66",
                                          "& .MuiSvgIcon-root": {
                                            fill: "#1f4b66",
                                          },
                                        }}
                                      >
                                        Next
                                        <ArrowForwardIcon />
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            )}

                            {step?.type === 3 && (
                              <div>
                                <audio
                                  controls
                                  style={{
                                    maxWidth: "100%",
                                    marginTop: "20px",
                                  }}
                                >
                                  <source
                                    src={step?.content?.url}
                                    type="audio/mpeg"
                                  />
                                  Your browser does not support the audio.
                                </audio>

                                {/* Buttons */}
                                <Box
                                  mt={5}
                                  style={{
                                    width: isLeftSideOpen ? "65%" : "88%",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center", // Vertically align buttons
                                    padding: "20px",
                                    backgroundColor: "#f7f7f7",
                                    zIndex: 1000, // Optional: Set a higher z-index value if needed
                                    position: "fixed", // Use "fixed" for fixed positioning
                                    bottom: 0,
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      justifyContent: "flex-start",
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        filteredSteps[index - 1]?.name || ""
                                      }
                                    >
                                      <span style={{ margin: "0 10px" }}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          onClick={() =>
                                            handleChapterNavigation(index - 1)
                                          }
                                          disabled={index === 0}
                                        >
                                          <ArrowBackIcon />
                                          Previous
                                        </Button>
                                      </span>
                                    </Tooltip>
                                  </Box>

                                  <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={() =>
                                      handleSubmitChapter(selectedStepId)
                                    }
                                    disabled={
                                      !enableSubmitButtn || step.finished
                                    }
                                    sx={{
                                      borderColor: "#1f4b66",
                                      color: "#1f4b66",
                                    }}
                                  >
                                    Conclude Chapter
                                    <PublishOutlinedIcon />
                                  </Button>

                                  <Box
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      justifyContent: "flex-end",
                                    }}
                                  >
                                    <Tooltip
                                      title={
                                        filteredSteps[index + 1]?.name || ""
                                      }
                                    >
                                      <span style={{ margin: "0 10px" }}>
                                        <Button
                                          variant="outlined"
                                          color="primary"
                                          onClick={() =>
                                            handleChapterNavigation(index + 1)
                                          }
                                          disabled={
                                            index === filteredSteps.length - 1
                                          }
                                        >
                                          Next
                                          <ArrowForwardIcon />
                                        </Button>
                                      </span>
                                    </Tooltip>
                                  </Box>
                                </Box>
                              </div>
                            )}

                            {step?.type === 4 && (
                              <div>
                                <img src={step?.content?.url} height={440} />
                                {/* Previous, Conclude Chapter, and Next Buttons */}
                                <Box
                                  style={{
                                    width: isLeftSideOpen ? "65%" : "88%",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "10px",
                                    backgroundColor: "#f7f7f7",
                                    zIndex: 1000, // Optional: Set a higher z-index value if needed
                                    position: "fixed", // Use "fixed" for fixed positioning
                                    bottom: 0,
                                  }}
                                >
                                  <Tooltip
                                    title={filteredSteps[index - 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index - 1)
                                        }
                                        disabled={index === 0}
                                      >
                                        <ArrowBackIcon />
                                        Previous
                                      </Button>
                                    </span>
                                  </Tooltip>

                                  <Box style={{ flex: 1, textAlign: "center" }}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleSubmitChapter(selectedStepId)
                                      }
                                      disabled={
                                        !enableSubmitButtn || step.finished
                                      }
                                      sx={{
                                        borderColor: "#1f4b66",
                                        color: "#1f4b66",
                                      }}
                                    >
                                      Conclude Chapter
                                      <PublishOutlinedIcon />
                                    </Button>
                                  </Box>

                                  <Tooltip
                                    title={filteredSteps[index + 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index + 1)
                                        }
                                        disabled={
                                          index === filteredSteps.length - 1
                                        }
                                      >
                                        Next
                                        <ArrowForwardIcon />
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </Box>
                              </div>
                            )}

                            {step?.type === 5 && (
                              <div>
                                {step?.content?.fileType === "PDF" && (
                                  <embed
                                    src={`https://popprobe-saas.s3.us-west-2.amazonaws.com/${step?.content?.key}`}
                                    width="100%"
                                    height="420px"
                                  />
                                )}
                                {["DOC", "EXCEL", "PPT"].includes(
                                  step?.content?.fileType
                                ) ? (
                                  <DocViewer
                                    pluginRenderers={DocViewerRenderers}
                                    documents={[
                                      {
                                        uri: `https://popprobe-saas.s3.us-west-2.amazonaws.com/${step?.content?.key}`,
                                        fileType: getFileType(
                                          step?.content?.fileType
                                        ),
                                        fileName: step?.content?.name,
                                      },
                                    ]}
                                    config={{
                                      header: {
                                        disableHeader: true,
                                        disableFileName: true,
                                        retainURLParams: true,
                                      },
                                    }}
                                    theme={{
                                      primary: "#5296d8",
                                      secondary: "#ffffff",
                                      tertiary: "#5296d899",
                                      text_primary: "#ffffff",
                                      text_secondary: "#5296d8",
                                      text_tertiary: "#00000099",
                                      disableThemeScrollbar: false,
                                    }}
                                    style={{ height: "400px" }}
                                    documentLoading={true}
                                  />
                                ) : (
                                  ""
                                )}

                                {/* Previous, Download File, Conclude Chapter, and Next Buttons */}
                                <div
                                  style={{
                                    width: isLeftSideOpen ? "65%" : "88%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    //flexWrap: "wrap",
                                    marginTop: "10px",
                                    padding: "20px",
                                    backgroundColor: "#f7f7f7",
                                    position: "fixed", // Use "fixed" for fixed positioning
                                    bottom: 0,
                                  }}
                                >
                                  <Tooltip
                                    title={filteredSteps[index - 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index - 1)
                                        }
                                        disabled={index === 0}
                                      >
                                        <ArrowBackIcon />
                                        Previous
                                      </Button>
                                    </span>
                                  </Tooltip>

                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      gap: "20px",
                                      flex: 1,
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() => {
                                        downloadFile(
                                          `https://popprobe-saas.s3.us-west-2.amazonaws.com/${step?.content?.key}`,
                                          step?.content?.name,
                                          step?.content?.fileType
                                        );
                                      }}
                                      style={{ backgroundColor: "e1f5fe" }}
                                      sx={{
                                        borderColor: "#1f4b66",
                                        color: "#1f4b66",
                                      }}
                                    >
                                      Download File
                                      <FileDownloadIcon />
                                    </Button>

                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleSubmitChapter(selectedStepId)
                                      }
                                      disabled={
                                        !enableSubmitButtn || step?.finished
                                      }
                                      style={{ backgroundColor: "e1f5fe" }}
                                      sx={{
                                        borderColor: "#1f4b66",
                                        color: "#1f4b66",
                                      }}
                                    >
                                      Conclude Chapter
                                      <PublishOutlinedIcon />
                                    </Button>
                                  </div>

                                  <Tooltip
                                    title={filteredSteps[index + 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index + 1)
                                        }
                                        disabled={
                                          index === filteredSteps.length - 1
                                        }
                                      >
                                        Next
                                        <ArrowForwardIcon />
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            )}

                            {step?.type === 6 && (
                              <div>
                                <p>URL Details:</p>
                                <p>URL: {step?.content?.url}</p>
                                <iframe
                                  src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                    step?.content?.url
                                  )}&embedded=true`}
                                  width="100%"
                                  height="450px"
                                  title="Embedded Content"
                                ></iframe>

                                {/* Previous and Next Buttons */}
                                <div
                                  style={{
                                    width: isLeftSideOpen ? "65%" : "88%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    marginTop: "10px",
                                    padding: "20px",

                                    backgroundColor: "#f7f7f7",
                                    position: "fixed", // Use "fixed" for fixed positioning
                                    bottom: 0,
                                  }}
                                >
                                  <Tooltip
                                    title={filteredSteps[index - 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index - 1)
                                        }
                                        disabled={index === 0}
                                      >
                                        <ArrowBackIcon />
                                        Previous
                                      </Button>
                                    </span>
                                  </Tooltip>

                                  <div style={{ textAlign: "center", flex: 1 }}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      onClick={() =>
                                        handleSubmitChapter(selectedStepId)
                                      }
                                      disabled={
                                        !enableSubmitButtn || step?.finished
                                      }
                                      style={{
                                        backgroundColor: "e1f5fe",
                                        borderColor: "#1f4b66",
                                        color: "#1f4b66",
                                      }}
                                    >
                                      Conclude Chapter
                                      <PublishOutlinedIcon />
                                    </Button>
                                  </div>

                                  <Tooltip
                                    title={filteredSteps[index + 1]?.name || ""}
                                  >
                                    <span>
                                      <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() =>
                                          handleChapterNavigation(index + 1)
                                        }
                                        disabled={
                                          index === filteredSteps.length - 1
                                        }
                                      >
                                        Next
                                        <ArrowForwardIcon />
                                      </Button>
                                    </span>
                                  </Tooltip>
                                </div>
                              </div>
                            )}
                          </div>

                          {step?.content ? (
                            <Typography
                              variant="body1"
                              component="div"
                              sx={{ mt: 1 }}
                            ></Typography>
                          ) : (
                            <p>No data available.</p>
                          )}
                        </div>
                      </>
                    );
                  }
                  return null;
                })}
              {assessmentMode && (
                <div
                  style={{
                    padding: "15px",
                  }}
                >
                  {selectedAssessment ? (
                    <SubmitAssessment title="Popprobe | LMS" />
                  ) : (
                    <div>
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          textAlign: "left",
                          marginTop: 2,
                          mb: 2,
                        }}
                        onClick={() => handleAssessmentClick(nextAssessment)}
                      >
                        Submit next assessment: {nextAssessment?.title}
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageStepComponent;
