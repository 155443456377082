/** @format */

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TablePagination from "@mui/material/TablePagination";
import { useNavigate, useLocation } from "react-router-dom";
import { GeneratePdfApi, SubmissionListApi } from "../redux/actions/Checklist";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import PropTypes from "prop-types";
import { IconButton } from "@mui/material";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import moment from "moment";
import Header from "./submissionTabs/Header";
import Progressbar from "../GlobalProgress/Progressbar";
import View from "../images/View.svg";
import ViewHover from "../images/ViewHover.svg";
import { DataGrid, GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import CircleIcon from "@mui/icons-material/Circle";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import SingleGlobalTable from "../GlobalComponent/SingleGlobalTable";
import { ToastContainer } from "react-toastify";
import { ListUserApi, UserSubRoleApi } from "../redux/actions/User";
import { ListStoreApi } from "../redux/actions/Store";
import InfoIcon from "@mui/icons-material/Info";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const Submission = (props) => {
  // console.log("Submission props", props);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const tableData = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionList &&
      state.checklist.submissionList.data &&
      state.checklist.submissionList.data.data &&
      state.checklist.submissionList.data.data.formsData
  );
  const totalpage = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionList &&
      state.checklist.submissionList.data &&
      state.checklist.submissionList.data.data &&
      state.checklist.submissionList.data.data.paginate
  );
  const submissionList = useSelector(
    (state) =>
      state.checklist &&
      state.checklist.submissionList &&
      state.checklist.submissionList.data &&
      state.checklist.submissionList.data.data &&
      state.checklist.submissionList.data.data.formsData
  );

  const selectedSubRole = useSelector(
    (state) =>
      state &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer &&
      state?.subroleSelecetedReducer?.subrloeSelectedData
  );

  const Loader = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  const loading = useSelector(
    (state) => state.checklist && state.checklist.loading
  );

  const generatePdfSelector = useSelector(
    (state) =>
      state?.checklist &&
      state?.checklist?.generatePdf &&
      state?.checklist?.generatePdf?.data &&
      state?.checklist?.data?.message
  );
  // console.log("generatePdfSelector", generatePdfSelector);
  var pageno = localStorage.getItem("submissionPageno");
  var rowPerPage = localStorage.getItem("submissionRowPerPage");

  const [page, setPage] = useState((pageno && parseInt(pageno)) || 1);
  const [rowsPerPage, setRowsPerPage] = useState(
    (rowPerPage && parseInt(rowPerPage)) || 10
  );
  const [submissionListData, setSubmissionListData] = useState([]);
  const [localPageNo, setLocalPageNo] = useState(1);
  const [subRole, setSubRole] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [generatePdfMessage, setGeneratePdfMessage] = useState("");
  const [indexHover, setIndexHover] = useState(0);
  const [loader, setLoader] = useState(false);
  const [hover, setHover] = useState({
    View: false,
  });

  const storedDateString = localStorage.getItem("storedDateData");
  const storedDateObject = JSON.parse(storedDateString);
  const submissionFilter = localStorage.getItem("submissionFilterData") || "{}";
  const submissionFilterData = JSON.parse(submissionFilter);

  const lq = localStorage.getItem("searchedData");
  const searchQ = lq || "";

  console.log("submissionFilterData ==>", submissionFilterData);

  const payloadRequest = {
    body: {
      page: page,
      per_page: rowsPerPage,
      sort_as: "updatedAt",
      sort_by: "desc",
      Role: [],
      checklistType: submissionFilterData.checklistType,
      userId: submissionFilterData?.userId?.map((item) => item._id) || [],
      storeId: submissionFilterData?.storeId?.map((item) => item._id) || [],
      frequency: submissionFilterData.frequency,
      // q: props.searchQuery || "",
      q: searchQ || props.searchQuery || "",
    },
  };

  const [permission, setPermission] = useState({});

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
    }
  }, []);

  // useEffect(() => {
  //   if (searchQ === "") {
  //     checklistProgressPostAPI(payloadRequest);
  //   }
  // }, [searchQ]);

  // useEffect(() => {
  //   checklistProgressPostAPI(payloadRequest);
  // }, [searchQ]);

  // Pagination issue fixed here
  // useEffect(() => {
  //   const updatedPayloadRequest = {
  //     body: {
  //       page: page,
  //       per_page: rowsPerPage,
  //       sort_as: "updatedAt",
  //       sort_by: "desc",
  //       Role: [],
  //       q: searchQ || props.searchQuery || "",
  //     },
  //   };

  //   checklistProgressPostAPI(updatedPayloadRequest);
  // }, [rowsPerPage, searchQ]);

  useEffect(() => {
    if (selectedSubRole) {
      setSubRole(selectedSubRole);
    }
  }, [selectedSubRole]);

  useEffect(() => {
    if (generatePdfSelector) {
      setGeneratePdfMessage(generatePdfSelector);
    }
  }, [generatePdfSelector]);

  // useEffect(() => {
  //   var localPageno = localStorage.getItem("pageno");
  //   payloadRequest.body.page = localPageno ? parseInt(localPageno) : 1;
  //   checklistProgressPostAPI(payloadRequest);
  // }, []);

  useEffect(() => {
    if (tableData) {
      setLoader(true);
      setSubmissionListData(tableData);
      setTimeout(() => {
        setLoader(false);
      }, 2000);
    }
  }, [tableData]);

  const handleClick = (row) => {
    navigate(`/checklist/submission/view-form`, { state: { id: row?._id } });
  };

  const handleDownload = (row) => {
    if (row?.pdfReport) {
      const encodedUrl = encodeURI(row.pdfReport);
      const decodedUrl = decodeURI(encodedUrl);
      const link = document.createElement("a");
      link.href = decodedUrl;
      link.setAttribute("target", "_blank");
      link.setAttribute("download", "report.pdf");
      link.click();
    }
  };

  const checklistProgressPostAPI = (payload) => {
    const today = moment().format("YYYY-MM-DD");
    const id = localStorage.getItem("companyId");

    // const payloadRequest = {
    //   body: {
    //     page: page,
    //     per_page: rowsPerPage,
    //     sort_as: "updatedAt",
    //     sort_by: "desc",
    //     Role: [],
    //     q: searchQ || props.searchQuery || "",
    //   },
    //   url: "",
    // };
    // console.log("payload", payload);
    const payloadRequest = {
      body: {
        page: payload.body.page || page,
        per_page: payload.body.per_page || rowsPerPage,
        sort_as: "updatedAt",
        sort_by: "desc",
        Role: payload.body.Role || [],
        // q: payload.body.searchQ || props.searchQuery || "",
        q: searchQ || props.searchQuery || "",
        checklistType: payload.body.checklistType || "",
        userId: payload.body.userId,
        storeId: payload.body.storeId,
        frequency: payload.body.frequency,
      },
      url: "",
    };

    if (storedDateObject?.startDate && storedDateObject?.endDate) {
      payloadRequest.url =
        BASE_URL +
        `checklists/progress?company_id=${id}&StartDate=${storedDateObject?.startDate}&EndDate=${storedDateObject?.endDate}`;
    } else {
      const startDate = today;
      const endDate = today;
      payloadRequest.url =
        BASE_URL +
        `checklists/progress?company_id=${id}&StartDate=${startDate}&EndDate=${endDate}`;
    }
    dispatch(SubmissionListApi(payloadRequest));
  };

  const handleGeneratePDFInSubmission = () => {
    const _id = localStorage.getItem("companyId");
    const data = {
      url: BASE_URL + `generatePdf/missed?company_id=${_id}`,
    };
    setLoader(true);
    dispatch(GeneratePdfApi(data));
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  // const handleGeneratePDFInSubmission = () => {
  //   setLoader(true);
  //   const _id = localStorage.getItem("companyId");
  //   const data = {
  //     url: BASE_URL + `/generatePdf/missed?company_id=${_id}`,
  //   };
  //   dispatch(GeneratePdfApi(data))
  //     .then((response) => {
  //       toast.info(generatePdfMessage);
  //     })
  //     .catch((error) => {
  //       toast.error("PDF generation failed.");
  //     });
  //   setTimeout(() => {
  //     setLoader(false);
  //   }, 2000);
  // };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange, showFirstButton } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        {showFirstButton && (
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="first page"
          >
            {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
          </IconButton>
        )}
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const label = { inputProps: { "aria-label": "Color switch demo" } };

  const handleSubrolesSelect = (values) => {
    setSubRole(values);
  };

  useEffect(() => {
    if (subRole) {
      // const payloadRequestStore = {
      //   body: {
      //     per_page: 2000000,
      //     page: 1,
      //     sort_as: "desc",
      //     sort_by: "title",
      //     approved: true,
      //   },
      // };

      payloadRequest.body.Role = subRole;
      checklistProgressPostAPI(payloadRequest);
      // listUser();
      // listStore(payloadRequestStore);
      // listSubRole();
    }
  }, [subRole]);

  // const listUser = (data) => {
  //   const datapayload = {
  //     body: {
  //       pagination: {
  //         page: 1,
  //         per_page: 2000000000000,
  //       },
  //     },
  //   };

  //   const id = localStorage.getItem("companyId");
  //   datapayload.url = BASE_URL + `users/index?company_id=${id}`;
  //   dispatch(ListUserApi(datapayload));
  // };

  // const listStore = (data) => {
  //   const id = localStorage.getItem("companyId");
  //   data.url = BASE_URL + `stores/index?company_id=${id}`;
  //   dispatch(ListStoreApi(data));
  // };

  // const listSubRole = () => {
  //   const id = localStorage.getItem("companyId");
  //   const data = { url: BASE_URL + `Listsubrole?company_id=${id}` };
  //   dispatch(UserSubRoleApi(data));
  // };

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      setLoader(true);
      payloadRequest.body.q = searchValue;
      payloadRequest.body.page = 1;
      setPage(1);
      checklistProgressPostAPI(payloadRequest);
      localStorage.setItem("searchedData", searchQ);

      setTimeout(() => {
        setLoader(false);
      }, 500);
    }
  };

  const searchOnChange = (value) => {
    setSearchValue(value);
  };

  useEffect(() => {
    if (pageno) {
      setPage(parseInt(pageno));
      setLocalPageNo(parseInt(pageno));
    }
  }, [pageno]);

  const handleSearch = () => {
    // console.log("searchValue", searchValue);
    var localPageno = localStorage.getItem("submissionPageno");
    payloadRequest.body.q = searchValue;

    checklistProgressPostAPI(payloadRequest);
  };

  const handleChangePage = (event, newPage) => {
    localStorage.setItem("submissionPageno", newPage + 1);
    if (localPageNo) {
      setPage(localPageNo);
    } else {
      setPage(newPage + 1);
    }

    payloadRequest.body.page = newPage + 1;
    payloadRequest.body.Role = selectedSubRole;
    payloadRequest.body.q = searchValue;
    checklistProgressPostAPI(payloadRequest);
  };

  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    localStorage.setItem(
      "submissionRowPerPage",
      parseInt(event.target.value, 10)
    );
    setPage(1);
    payloadRequest.body.page = 1;
    localStorage.removeItem("submissionPageno");
    localStorage.setItem("submissionPageno", 1);
    payloadRequest.body.per_page = parseInt(event.target.value, 10);
    payloadRequest.body.Role = selectedSubRole;
    payloadRequest.body.q = searchValue;
    checklistProgressPostAPI(payloadRequest);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  };

  const columns = [
    // {
    //   field: "S.No.",
    //   headerName: "S.No.",
    //   renderHeader: (params) => <strong>{"S.No"}</strong>,
    //   flex: 1,
    //   align: "center",
    //   headerAlign: "center",
    //   valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
    //   renderCell: (params) => {
    //     const index =
    //       submissionListData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;
    //     return (
    //       <div
    //         style={{
    //           display: "flex",
    //           width: "133px",
    //         }}
    //       >
    //         <p
    //           className="text-short"
    //           style={{ marginLeft: "10px", fontWeight: "normal" }}
    //         >
    //           {index}
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      field: "S.No.",
      headerName: "S.No.",
      renderHeader: (params) => (
        <strong style={{ marginLeft: "0%" }}>{"S.No"}</strong>
      ),
      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => params.rowIndex + 1 + (page - 1) * rowsPerPage,
      renderCell: (params) => {
        const index =
          submissionListData.indexOf(params.row) + 1 + (page - 1) * rowsPerPage;

        const expiresAt = params?.row?.expires_at;
        const currentDate = new Date()
          .toISOString()
          .slice(0, 10)
          .replace(/-/g, "");
        const isExpired =
          expiresAt && parseInt(expiresAt) < parseInt(currentDate);

        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              height: "100%",
              minWidth: "50px",
            }}
          >
            <div
              className={
                params.row.type === 1
                  ? "table-left-border-grey"
                  : "table-left-border-darkgrey"
              }
              style={{
                width: "5px",
                marginLeft: "-10px",
                height: "100%",
                fontSize: "12px",
              }}
            />
            <LightTooltip
              title={
                params.row.approvalStatus === "disapproved"
                  ? "Checklist Disapproved"
                  : params.row.approvalStatus === "approved"
                  ? "Checklist Approved"
                  : ""
              }
            >
              <div
                className={
                  params.row.approvalStatus === "disapproved"
                    ? "table-left-border-red"
                    : params.row.approvalStatus === "approved"
                    ? "table-left-border-green"
                    : ""
                }
                style={{
                  width: "5px",
                  marginLeft: "-10px",
                  height: "100%",
                  fontSize: "12px",
                }}
              />
            </LightTooltip>
            <p
              className="text-short"
              style={{
                margin: "0 auto",
                textAlign: "left",
                fontSize: "16px",
              }}
            >
              {index}
            </p>
          </div>
        );
      },
    },

    {
      field: "User",
      headerName: "User",
      renderHeader: () => <strong>{"User"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.user?.userName) {
          result.push(params?.row?.user?.userName);
        } else {
          result = ["Unknown"];
        }
        let results = result.join(", ");
        return (
          <div
            style={{
              display: "flex",
              width: "133px",
            }}
          >
            <LightTooltip title={results}>
              <p
                className="text-short"
                style={{ marginLeft: "10px", fontWeight: "normal" }}
              >
                {results}
              </p>
            </LightTooltip>
          </div>
        );
      },
    },
    {
      field: "Role",
      headerName: "Role",
      renderHeader: () => <strong>{"Role"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.role) {
          result.push(params?.row?.role);
        } else {
          result = ["Unknown"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short"> {results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Store	",
      headerName: "Store",
      renderHeader: () => <strong>{"Store"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.store?.title) {
          result.push(params?.row?.store?.title);
        } else {
          result = ["0"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short"> {results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Category",
      headerName: "Category",
      renderHeader: () => <strong>{"Category"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row?.category?.categoryTitle) {
          result.push(params?.row?.category?.categoryTitle);
        } else {
          result = ["0"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="text-short"> {results}</p>
          </LightTooltip>
        );
      },
    },
    {
      field: "Forms Type",
      headerName: "Forms Type",
      renderHeader: () => <strong>{"Forms Type"}</strong>,
      flex: 1,
      align: "center",
      maxWidth: 95,
      headerAlign: "left",
      marginLeft: "-2%",
      renderCell: (params) => {
        // console.log("params", params);
        let result = "";
        if (params?.row?.type === 1) {
          result = "Checklist";
        } else {
          result = "Audit";
        }

        return (
          <LightTooltip title={result}>
            <div
              className="text-short3"
              // style={{
              //   textAlign: "left",
              //   color: params?.row?.type === 1 ? "#F39A4C" : "green",
              // }}
            >
              {result}
            </div>
          </LightTooltip>
        );
      },
    },
    {
      field: "Forms Completed",
      headerName: "Forms Completed",
      renderHeader: () => <strong>{"Forms Completed"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        if (params?.row) {
          result.push(params?.row?.forms);
        } else {
          result = ["0%"];
        }

        return !params?.row?.forms.length ? (
          <div className="text-short3">N/A</div>
        ) : (
          <ul style={{ paddingInlineStart: "0px" }}>
            {params?.row?.forms?.map((item, index) => (
              <LightTooltip title={item.title}>
                <li
                  className="my-list !important"
                  key={index}
                  // className="text-short"
                  style={{
                    textAlign: "start",
                  }}
                >
                  <CircleIcon
                    // sx={{ fontSize: 10 }}
                    className="font"
                    style={{ paddingRight: 4 }}
                  />
                  {item.title}
                </li>
              </LightTooltip>
            ))}
          </ul>
        );
      },
    },

    {
      field: "Weightage Score",
      headerName: "Weightage Score",
      renderHeader: () => <strong>{"Weightage Score"}</strong>,
      flex: 2,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];

        // if (params?.row?.forms) {
        //   for (let data of params?.row?.forms) {
        //     result.push(
        //       `${(
        //         (data?.calculatedOptionScore / data?.calculatedQuestionScore) *
        //         100
        //       ).toFixed(2)}% `
        //     );
        //   }
        // } else {
        //   result = ["0%"];
        // }
        if (params?.row?.forms) {
          for (let data of params?.row?.forms) {
            const percentage =
              data?.calculatedQuestionScore !== 0
                ? (
                    (data?.calculatedOptionScore /
                      data?.calculatedQuestionScore) *
                    100
                  ).toFixed(2) + "%"
                : "0.00%";
            result.push(percentage);
          }
        } else {
          result = ["0%"];
        }

        return !params?.row?.forms.length ? (
          <div className="text-short3">N/A</div>
        ) : (
          <ul style={{ paddingInlineStart: "0px" }}>
            {result?.map((item, index) => (
              <LightTooltip title={item}>
                <li
                  className="my-list-w  !important"
                  key={index}
                  style={{
                    textAlign: "start",
                  }}
                >
                  <CircleIcon className="font" style={{ paddingRight: 4 }} />
                  {item}
                </li>
              </LightTooltip>
            ))}
          </ul>
        );
      },
    },

    {
      field: "Progress",
      headerName: "Progress",
      renderHeader: () => <strong>{"Progress"}</strong>,

      flex: 1,
      align: "center",
      headerAlign: "center",
      valueGetter: (params) => {
        let result = [];
        if (params?.row?.completePercentage) {
          result.push(`${params?.row?.completePercentage.toFixed(2)}%`);
        } else {
          result = ["0%"];
        }
        return result.join(", ");
      },
    },
    {
      field: "Submitted on",
      headerName: "Submitted on",
      renderHeader: () => <strong>{"Updated on"}</strong>,

      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        let result = [];
        let fromateDate = moment(params?.row?.updatedAt).format(
          "h:mm A MMM D,YY"
        );
        if (fromateDate) {
          result.push(fromateDate);
        } else {
          result = ["0"];
        }
        let results = result.join(", ");
        return (
          <LightTooltip title={results}>
            <p className="my-list-w  !important">{results}</p>
          </LightTooltip>
        );
      },
    },

    {
      field: "Action",
      headerName: "Action",
      renderHeader: () => <strong>{"Action"}</strong>,
      flex: 1,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const hasPdfReport = !!params?.row?.pdfReport;
        const viewBtnStyle = {
          height: "35px",
          cursor: "pointer",
          margin: "0px 3px 0px 3px",
        };
        if (!hasPdfReport) {
          viewBtnStyle.marginLeft = "30px";
        }
        return (
          <div>
            {hasPdfReport && (
              <CloudDownloadIcon
                style={{
                  ...viewBtnStyle,
                }}
                title="Download Report"
                onClick={() => handleDownload(params?.row)}
              />
            )}
            <Tooltip title="View">
              <img
                src={
                  hover.View && indexHover === params?.row?._id
                    ? ViewHover
                    : View
                }
                alt="VIEW"
                style={{
                  ...viewBtnStyle,
                }}
                onClick={() => handleClick(params?.row)}
                onMouseOver={() => {
                  setHover({ ...hover, View: true });
                  setIndexHover(params?.row?._id);
                }}
                onMouseLeave={() => {
                  setHover({ ...hover, View: false });
                }}
              />
            </Tooltip>
            {hover.Download && (
              <span
                style={{
                  position: "absolute",
                  backgroundColor: "rgba(0,0,0,0.8)",
                  color: "#fff",
                  padding: "5px",
                  borderRadius: "4px",
                  marginLeft: "5px",
                  fontSize: "14px",
                }}
              >
                {"Download Report"}
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleFilter = (filterObject) => {
    const today = moment().format("YYYY-MM-DD");
    const id = localStorage.getItem("companyId");

    payloadRequest.body.checklistType = filterObject.checklistType;
    payloadRequest.body.userId =
      filterObject?.userId?.map((item) => item._id) || [];
    payloadRequest.body.storeId =
      filterObject?.storeId?.map((item) => item._id) || [];
    payloadRequest.body.frequency = filterObject.frequency;
    payloadRequest.body.q = localStorage.getItem("searchedData");
    payloadRequest.body.page = 1;
    payloadRequest.body.per_page = (rowPerPage && parseInt(rowPerPage)) || 10;

    localStorage.setItem("submissionPageno", 1);

    // payloadRequest.body.Role = [filterObject.Role];

    // checklistProgressPostAPI(payloadRequest);

    if (filterObject?.startDate && filterObject?.endDate) {
      payloadRequest.url =
        BASE_URL +
        `checklists/progress?company_id=${id}&StartDate=${filterObject?.startDate}&EndDate=${filterObject?.endDate}`;
    } else {
      const startDate = today;
      const endDate = today;
      payloadRequest.url =
        BASE_URL +
        `checklists/progress?company_id=${id}&StartDate=${startDate}&EndDate=${endDate}`;
    }
    dispatch(SubmissionListApi(payloadRequest));
  };

  return (
    <Box>
      <ToastContainer autoClose={3000} />
      <Header
        handleChange={handleChange}
        selectedValue={selectedValue}
        handleKeyDown={handleKeyDown}
        searchOnChange={searchOnChange}
        searchValue={searchValue}
        handleSubrolesSelect={handleSubrolesSelect}
        submissionCancle={"submissionCancle"}
        submissionListData={submissionListData}
        permission={permission}
        setPage={setPage}
        handleSearch={handleSearch}
        onGeneratePDF={handleGeneratePDFInSubmission}
        handleFilter={handleFilter}
      />
      <SingleGlobalTable
        data={submissionListData}
        columns={columns}
        totalCount={totalpage?.total_item || 0}
        // loading={loader}
        loading={Loader}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
      <p>
        Copyright ©2024{" "}
        <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a>{" "}
        All Right Reserved.
      </p>
    </Box>
  );
};

export default Submission;
