/** @format */

import React, { useContext, useEffect, useState } from "react";
import { TDRVideoTypeApi } from "../../../redux/actions/LmsAction";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { useDispatch, useSelector } from "react-redux";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import Progressbar from "../../../GlobalProgress/Progressbar";
import { useNavigate } from "react-router-dom";
import { green } from "@mui/material/colors";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AllInclusiveIcon from "@mui/icons-material/AllInclusive";
import ScheduleIcon from "@mui/icons-material/Schedule";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { AuditorContext } from "../../../../context/auditorContext";
import { ToastContainer } from "react-toastify";
import ProgressbarTransparent from "../../../GlobalProgress/ProgressbarTransparent";
import {
  ArrowBack as ArrowBackIcon,
  ExpandMore as ExpandMoreIcon,
} from "@material-ui/icons";
import moment from "moment";

const MyTrainingView = (props) => {
  const location = useLocation();
  const CourseDetailsData = location?.state;
  let { storeId, storeTitle, companyId } = useContext(AuditorContext);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [detailsDataVideoType, setDetailsDataVideoType] = useState([]);
  const [CourseType, setCourseType] = useState("");
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    const selectedStore = localStorage.getItem("auditorSelectedStore");
    companyId = localStorage.getItem("companyId");
    if (selectedStore) {
      const parseselectedStore = JSON.parse(selectedStore);
      storeId = parseselectedStore._id;
      storeTitle = parseselectedStore.title;
    }
  }, []);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const allTrainingProgress = useSelector(
    (state) =>
      state?.lms &&
      state?.lms?.trDetailsVideoTypeData &&
      state?.lms?.trDetailsVideoTypeData?.data &&
      state?.lms?.trDetailsVideoTypeData?.data?.data
  );
  console.log("allTrainingProgress", allTrainingProgress);
  const Loader = useSelector((state) => state.lms && state.lms.loading);

  const paginationRequest = {
    body: {
      trainingId: location?.state?._id,
      storeId: storeId,
    },
  };

  useEffect(() => {
    if (allTrainingProgress) {
      setDetailsDataVideoType(allTrainingProgress);
    }
  }, [allTrainingProgress]);

  useEffect(() => {
    individualList(paginationRequest);
  }, []);

  useEffect(() => {
    if (detailsDataVideoType.steps && detailsDataVideoType.steps.length > 0) {
      detailsDataVideoType.steps.map((step, stepIndex) => {
        if (stepIndex === 0) {
          if (step.type === 1) {
            setCourseType(step.type);
          } else if (step.type === 2) {
            setCourseType(step.type);
          } else if (step.type === 3) {
            setCourseType(step.type);
          } else if (step.type === 4) {
            setCourseType(step.type);
          } else if (step.type === 5) {
            setCourseType(step.type);
          } else if (step.type === 6) {
            setCourseType(step.type);
          }
        }
        return null;
      });
    }
  }, [detailsDataVideoType.steps]);

  const individualList = (paginationRequest) => {
    const compId = localStorage.getItem("compId");
    paginationRequest.url =
      BASE_URL + `training/details?company_id=${companyId}`;
    dispatch(TDRVideoTypeApi(paginationRequest));
  };

  useEffect(() => {
    localStorage.removeItem("totalSteps");
  }, []);
  useEffect(() => {
    if (detailsDataVideoType && detailsDataVideoType.hasEnrolled === true) {
    }
  }, [detailsDataVideoType, CourseType]);

  const handleEnrollButtonClick4 = () => {
    if (detailsDataVideoType) {
      const totalSteps = detailsDataVideoType?.steps?.map((step) => step.id);
      localStorage.setItem("totalSteps", totalSteps.join(","));
      const trainingId = detailsDataVideoType?._id;
      localStorage.setItem("trainingId", trainingId);
    }
    navigate(
      `/auditor-home/my-trainings/my-trainings-view/newTraining-image-type`,
      {
        state: detailsDataVideoType,
      }
    );
  };

  const handleAccordionToggle = () => {
    setIsAccordionOpen((prevIsAccordionOpen) => !prevIsAccordionOpen);
  };

  const handleBackButtonClick1 = () => {
    navigate("/auditor-home/my-trainings");
  };

  return (
    <Box>
      {Loader && <ProgressbarTransparent play />}
      <ToastContainer autoClose={3000} />
      <Box className="header-card">
        <Accordion
          expanded={isAccordionOpen}
          sx={{
            borderRadius: "4px",
            backgroundColor: "#f5f5f5",
            boxShadow: "none",
            "& .MuiAccordionSummary-content": {
              margin: 0,
              alignItems: "center",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            onClick={handleAccordionToggle}
            sx={{ justifyContent: "space-between" }}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <Box className="header-card">
                <Grid container alignItems="center">
                  <Grid item xs={2}>
                    <IconButton onClick={handleBackButtonClick1}>
                      <ArrowBackIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={10}></Grid>
                </Grid>
              </Box>
              <Typography align="center">
                {isAccordionOpen
                  ? detailsDataVideoType?.name
                  : detailsDataVideoType?.name}
              </Typography>
            </div>
            <div style={{ marginLeft: "auto" }}>
              <Typography align="center">
                <strong>{storeTitle}</strong>
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div
              style={{
                border: "1px solid",
                borderRadius: "4px",
                padding: "10px",
                background: "white",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Name:</strong> {detailsDataVideoType?.name}
                  </p>
                </Typography>

                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Summary:</strong> {detailsDataVideoType?.summary}
                  </p>
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  {detailsDataVideoType?.duration ? (
                    <div style={{ marginLeft: "1px", textAlign: "center" }}>
                      <AccessTimeIcon />
                      <span>
                        <strong>Duration:</strong>
                      </span>
                      {detailsDataVideoType?.duration}
                    </div>
                  ) : (
                    <div>
                      {detailsDataVideoType?.duration ? (
                        <span>
                          <strong>Duration:</strong>{" "}
                          {detailsDataVideoType?.duration}
                        </span>
                      ) : (
                        <span>
                          <strong>Duration:</strong> 0
                        </span>
                      )}
                    </div>
                  )}
                </Typography>
                <Typography sx={{ mt: 1 }}>
                  {detailsDataVideoType?.updatedAt ? (
                    <React.Fragment>
                      <span>
                        <strong>Last updated:</strong>{" "}
                      </span>
                      {moment(detailsDataVideoType?.updatedAt).format(
                        "YYYY/MM/DD HH:mm:ss"
                      ) || <span>"N/A"</span>}
                    </React.Fragment>
                  ) : (
                    <span>
                      <strong>Last updated:</strong> N/A
                    </span>
                  )}
                  {/* {(() => {
                        const now = new Date();
                        const updatedAt = new Date(
                          detailsDataVideoType?.updatedAt
                        );
                        const diffInMs = now - updatedAt;

                        const seconds = Math.floor(diffInMs / 1000);
                        const minutes = Math.floor(seconds / 60);
                        const hours = Math.floor(minutes / 60);
                        const days = Math.floor(hours / 24);
                        const months = Math.floor(days / 30);
                        const years = Math.floor(months / 12);

                        if (years > 0) {
                          return <span>{years} year(s) ago</span>;
                        } else if (months > 0) {
                          return <span>{months} month(s) ago</span>;
                        } else if (days > 0) {
                          return <span>{days} day(s) ago</span>;
                        } else {
                          return <span>{hours} hour(s) ago</span>;
                        }
                      })()}
                    </React.Fragment> */}
                  {/* // ) : ( //{" "} */}
                  {/* <span> */}
                  {/* // <strong>Last updated:</strong> N/A //{" "} */}
                  {/* </span> */}
                  {/* // )} */}
                </Typography>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Completion Progress:</strong>(
                    {detailsDataVideoType?.completedPercentage?.toFixed(
                      detailsDataVideoType?.completedPercentage ? 2 : 0
                    )}
                    %)
                  </p>
                </Typography>
                <div style={{ width: "250px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={detailsDataVideoType?.completedPercentage}
                    sx={{ height: 10 }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${detailsDataVideoType?.completedPercentage}%`,
                        backgroundColor:
                          detailsDataVideoType?.completedPercentage === 100
                            ? "green"
                            : "red",
                      }}
                    />
                  </LinearProgress>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Assessment Progress: </strong>(
                    {detailsDataVideoType?.assessmentProgress?.toFixed(
                      detailsDataVideoType?.assessmentProgress ? 2 : 0
                    )}
                    %)
                  </p>
                </Typography>
                <div style={{ width: "250px" }}>
                  <LinearProgress
                    variant="determinate"
                    value={detailsDataVideoType?.assessmentProgress}
                    sx={{ height: 10 }}
                  >
                    <div
                      style={{
                        height: "100%",
                        width: `${detailsDataVideoType?.assessmentProgress}%`,
                        backgroundColor:
                          detailsDataVideoType?.assessmentProgress === 100
                            ? "green"
                            : "red",
                      }}
                    />
                  </LinearProgress>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "0 20%",
                }}
              >
                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <p>
                    <strong>Assessment Status:</strong>{" "}
                    {"(" +
                      detailsDataVideoType?.assessmentProgress?.toFixed(
                        detailsDataVideoType?.assessmentProgress ? 2 : 0
                      ) +
                      "%)"}
                  </p>
                </Typography>

                <Typography variant="body1" component="div" sx={{ mt: 1 }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      marginRight: "16%",
                      background:
                        detailsDataVideoType?.assessmentProgress >= 66
                          ? "green"
                          : detailsDataVideoType?.assessmentProgress >= 33
                          ? "gray"
                          : "red",
                      color: "white",
                      fontWeight: "bold",
                      fontSize: "18px",
                    }}
                  >
                    {detailsDataVideoType?.assessmentProgress}%
                  </div>
                </Typography>
              </div>
            </div>
          </AccordionDetails>
        </Accordion>
        {/* <Grid container spacing={1} alignItems="center">
          <Grid item xs={2} sx={{ ml: -1 }}>
            <IconButton onClick={handleBackButtonClick}>
              <ArrowBackIcon />
            </IconButton>
          </Grid>
          <Grid item xs={10}></Grid>
        </Grid> */}
      </Box>

      <Box
        style={{
          backgroundColor: "#F6F8FC",
          height: "80vh",
          marginTop: 1,
          padding: 1,
          borderRadius: 1,
          overflowX: "auto",
          overflowY: "scroll",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": {
            width: 0,
          },
          "&::-webkit-scrollbar-track": {
            padding: "12px 5px",
            backgroundColor: "#CBD4E1",
          },
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "#64748B",
            borderRadius: 8,
          },
        }}
      >
        {
          <Box sx={{ mt: 5, ml: 30, marginRight: 30 }}>
            <Typography
              align="center"
              variant="h4"
              color={
                detailsDataVideoType?.name ? "textPrimary" : "textSecondary"
              }
              sx={{ mt: 2, fontWeight: "bold", color: "gray" }}
            >
              {detailsDataVideoType?.name ? detailsDataVideoType.name : "N/A"}
            </Typography>

            <Typography
              align="center"
              variant="h6"
              color={
                detailsDataVideoType?.summary ? "textPrimary" : "textSecondary"
              }
              sx={{ mt: 2, color: "gray" }}
            >
              {detailsDataVideoType?.summary
                ? detailsDataVideoType.summary
                : "N/A"}
            </Typography>

            <Box sx={{ mt: 3, ml: 10, marginRight: 10 }}>
              <Typography align="left" variant="body1" sx={{ mt: 2, ml: 1 }}>
                {detailsDataVideoType?.updatedAt ? (
                  <React.Fragment>
                    <span>
                      <strong>Last updated:</strong>{" "}
                    </span>
                    {moment(detailsDataVideoType?.updatedAt).format(
                      "YYYY/MM/DD HH:mm:ss"
                    ) || <span>"N/A"</span>}
                  </React.Fragment>
                ) : (
                  // <React.Fragment>
                  //   <span>
                  //     <strong>Last updated:</strong>{" "}
                  //   </span>
                  //   {(() => {
                  //     const now = new Date();
                  //     const updatedAt = new Date(
                  //       detailsDataVideoType.updatedAt
                  //     );
                  //     const diffInMs = now - updatedAt;

                  //     const seconds = Math.floor(diffInMs / 1000);
                  //     const minutes = Math.floor(seconds / 60);
                  //     const hours = Math.floor(minutes / 60);
                  //     const days = Math.floor(hours / 24);
                  //     const months = Math.floor(days / 30);
                  //     const years = Math.floor(months / 12);

                  //     if (years > 0) {
                  //       return <span>{years} year(s) ago</span>;
                  //     } else if (months > 0) {
                  //       return <span>{months} month(s) ago</span>;
                  //     } else if (days > 0) {
                  //       return <span>{days} day(s) ago</span>;
                  //     } else {
                  //       return <span>{hours} hour(s) ago</span>;
                  //     }
                  //   })()}
                  // </React.Fragment>
                  <span>
                    <strong>Last updated:</strong> N/A
                  </span>
                )}
              </Typography>

              <Typography align="left" variant="body1" sx={{ mt: 2, ml: 1 }}>
                <span>
                  <strong>Store:</strong> {storeTitle}
                </span>
              </Typography>
            </Box>

            <Button
              variant="contained"
              color="success"
              endIcon={<ArrowForwardIcon />}
              sx={{ mt: 3, bgcolor: green[500] }}
              onClick={handleEnrollButtonClick4}
            >
              {detailsDataVideoType.hasEnrolled ? "Continue" : "Enroll Now"}
            </Button>
            <Box sx={{ mt: 4, ml: 2, mr: 2 }}>
              {/* table  */}
              <Box sx={{ mt: 3, ml: 10, marginRight: 10 }}>
                <Typography
                  align="left"
                  variant="h6"
                  sx={{ color: "gray", marginBottom: "12px" }}
                >
                  What will you learn?
                </Typography>

                <TableContainer
                  sx={{ border: "1px solid", borderColor: "divider", mt: 4 }}
                >
                  <Table>
                    <TableBody>
                      {detailsDataVideoType?.steps?.map((step, index) => (
                        <TableRow
                          key={index}
                          sx={{ "&:last-child td": { borderBottom: 0 } }}
                        >
                          <TableCell
                            align="left"
                            sx={{
                              borderRight: "1px solid",
                              borderColor: "divider",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">{step?.name}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
            {/* table  */}
          </Box>
        }
      </Box>
    </Box>
  );
};

export default MyTrainingView;
