import { Card, Grid, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import { useDispatch } from "react-redux";
import SchedularViewHeader from "./SchedularViewHeader";
import HouseSidingIcon from "@mui/icons-material/HouseSiding";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { ViewSchedularApi } from "../../redux/actions/SchedularAction";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../redux/apiConfig/BaseUrl";
import PersonIcon from "@mui/icons-material/Person";
import UpdateIcon from "@mui/icons-material/Update";
import TitleIcon from "@mui/icons-material/Title";
import TaskIcon from "@mui/icons-material/Assignment";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import StoreIcon from "@mui/icons-material/Store";
import * as moment from "moment";

const SchecularView = (props) => {
  const location = useLocation();
  const { rowData } = location.state || {};
  const [detailsApiData, setDetailsApiData] = useState({});
  const dispatch = useDispatch();

  const schedularDetailsData = useSelector(
    (state) =>
      state?.scheduler &&
      state?.scheduler?.schedularView &&
      state?.scheduler?.schedularView?.data &&
      state?.scheduler?.schedularView?.data?.data
  );
  console.log("detailsApiData", detailsApiData);

  const detailsApi = () => {
    const id = localStorage.getItem("companyId");
    const paginationRequest = {
      url: BASE_URL + `DynamicJob/${rowData?._id}?company_id=${id}`,
    };
    dispatch(ViewSchedularApi(paginationRequest));
  };

  useEffect(() => {
    detailsApi();
  }, []);

  useEffect(() => {
    if (schedularDetailsData) {
      setDetailsApiData(schedularDetailsData);
    }
  }, [schedularDetailsData]);

  const renderFrequency = (frequency) => {
    switch (frequency) {
      case 1:
        return "One Time";
      case 2:
        return "Daily At";
      case 3:
        return "Weekly";
      case 4:
        return "Monthly On";
      default:
        return "N/A";
    }
  };

  const formatDate = (dateString) => {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(dateString).toLocaleString(undefined, options);
  };

  const renderStatus = (isActive) => {
    return isActive ? (
      <Typography color="green">Active</Typography>
    ) : (
      <Typography color="red">Inactive</Typography>
    );
  };

  return (
    <>
      <SchedularViewHeader />
      <Box
        sx={{ bgcolor: "#F6F8FC", borderRadius: "4px", mb: 1 }}
        className="header-card"
      >
        <Container>
          <Grid container spacing={2} sx={{ textAlign: "left", mt: 1 }}>
            <Grid item xs={12}>
              <Card
                sx={{ mb: 2, boxShadow: "none", borderRadius: "8px", p: 2 }}
              >
                <Typography variant="h5">Scheduler Details</Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {[
                    {
                      icon: <PersonIcon />,
                      label: "Created By:",
                      value: detailsApiData?.dynamicJob?.createdBy
                        ? detailsApiData?.dynamicJob?.createdBy?.name
                        : "N/A",
                    },
                    {
                      icon: <UpdateIcon />,
                      label: "Updated By:",
                      value: detailsApiData?.dynamicJob?.updatedBy
                        ? detailsApiData?.dynamicJob?.updatedBy?.name
                        : "N/A",
                    },
                    {
                      icon: <TitleIcon />,
                      label: "Token:",
                      value: detailsApiData?.issueLog?.tokenNumber || "N/A",
                    },
                    // {
                    //   icon: <TaskIcon />,
                    //   label: "Module:",
                    //   value: detailsApiData?.dynamicJob?.type || "N/A",
                    // },
                    // {
                    //   icon: <TaskIcon />,
                    //   label: "Task:",
                    //   value: detailsApiData?.dynamicJob?.task || "N/A",
                    // },
                    // {
                    //   icon: <ScheduleIcon />,
                    //   label: "Frequency:",
                    //   value: renderFrequency(
                    //     detailsApiData?.dynamicJob?.frequency
                    //   ),
                    // },
                    {
                      icon: <UpdateIcon />,
                      label: "Created At:",
                      value:
                        formatDate(detailsApiData?.dynamicJob?.createdAt) ||
                        "N/A",
                    },
                    {
                      icon: <UpdateIcon />,
                      label: "Updated At:",
                      value:
                        formatDate(detailsApiData?.dynamicJob?.updatedAt) ||
                        "N/A",
                    },
                    {
                      icon: <ScheduleIcon />,
                      label: "Date & Time:",
                      value: detailsApiData?.dynamicJob?.run_date
                        ? moment(detailsApiData.dynamicJob.run_date, moment.ISO_8601, true).isValid()
                          ? moment(detailsApiData.dynamicJob.run_date).format("MMMM D, YYYY [at] h:mm:ss A")
                          : "N/A"
                        : "N/A",
                    },
                                       
                    // {
                    //   icon: <CheckCircleIcon />,
                    //   label: "Status:",
                    //   value: renderStatus(detailsApiData?.dynamicJob?.isactive),
                    // },
                  ].map(({ icon, label, value }, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        {icon}
                        <span
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {label}
                        </span>
                        <span>{value}</span>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Card>
              <Card
                sx={{ mb: 2, boxShadow: "none", borderRadius: "8px", p: 2 }}
              >
                <Typography variant="h5">Other Details</Typography>
                <Grid container spacing={2} sx={{ mt: 2 }}>
                  {[
                    {
                      icon: <StoreIcon />,
                      label: "Store:",
                      value: detailsApiData?.issueLog?.store?.title || "N/A",
                    },
                    {
                      icon: <HouseSidingIcon />,
                      label: "Departments:",
                      value:
                        detailsApiData?.issueLog?.departments[0]?.deptName ||
                        "N/A",
                    },
                    {
                      icon: <TitleIcon />,
                      label: "Category:",
                      value: detailsApiData?.issueLog?.category || "N/A",
                    },
                    {
                      icon: <TaskIcon />,
                      label: "Sub Category:",
                      value: detailsApiData?.issueLog?.subCategory || "N/A",
                    },
                    {
                      icon: <TaskIcon />,
                      label: "Logged By:",
                      value: detailsApiData?.issueLog?.loggedBy?.name || "N/A",
                    },
                    {
                      icon: <ScheduleIcon />,
                      label: "Issue Status:",
                      value: detailsApiData?.issueLog?.issueStatus || "N/A",
                    },
                    {
                      icon: <UpdateIcon />,
                      label: "Created At:",
                      value:
                        formatDate(detailsApiData?.issueLog?.createdAt) ||
                        "N/A",
                    },
                    {
                      icon: <UpdateIcon />,
                      label: "Updated At:",
                      value:
                        formatDate(detailsApiData?.issueLog?.updatedAt) ||
                        "N/A",
                    },
                  ].map(({ icon, label, value }, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        {icon}
                        <span
                          style={{
                            marginLeft: "10px",
                            marginRight: "10px",
                            fontWeight: "bold",
                          }}
                        >
                          {label}
                        </span>
                        <span>{value}</span>
                      </Typography>
                    </Grid>
                  ))}
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default SchecularView;
