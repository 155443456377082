import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { FormControl, TextField } from "material-ui-core";
import { Autocomplete, Checkbox, Grid, ListItemText, MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { BASE_URL } from "../../../redux/apiConfig/BaseUrl";
import { EditCategotyApi, ModuleListListApi, SubModuleListsListApi } from "../../../redux/actions/DataManager";
import { useSelector } from "react-redux";
import "react-confirm-alert/src/react-confirm-alert.css";
import { IssueDepartmentListApi } from "../../../redux/actions/Issue";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(5),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const MenuProps = {
  disableScrollLock: true,
};

const EditCategoryModel = (props) => {
  const dispatch = useDispatch();
  const [newFieldValue, setNewFieldValue] = useState({});
  const valuesEdit = props?.editvalue;
  const [module, setModule] = useState([]);
  const [subModule, setSubModule] = useState([]);
  const [selectedmodule, setSelectedModule] = useState([]);
  const [selectedsubModule, setSelectedSubModule] = useState([]);
  const [departmentList, setDepartmentList] = useState([]);
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const modulesList = useSelector(
    (state) =>
      state &&
      state.apidataManager &&
      state.apidataManager.modulesList &&
      state.apidataManager.modulesList.data &&
      state.apidataManager.modulesList.data.data
  );

  const subModulesList = useSelector(
    (state) =>
      state &&
      state.apidataManager &&
      state.apidataManager.subModulesList &&
      state.apidataManager.subModulesList.data &&
      state.apidataManager.subModulesList.data.data
  );

  const departmentListData = useSelector(
    (state) =>
      (state.issue &&
        state.issue.departmentData &&
        state.issue.departmentData.data &&
        state.issue.departmentData.data.data &&
        state.issue.departmentData.data.data &&
        state.issue.departmentData.data.data.departments) ||
      []
  );

  const ListDepartment = (id) => {
    const data = {
      url: BASE_URL + `department/index?company_id=${id}`,
      body: {
        pagination: {
          page: 1,
          per_page: 500,
        },
      },
    };
    dispatch(IssueDepartmentListApi(data));
  };

  useEffect(() => {
    const id = localStorage.getItem("companyId");
    ListDepartment(id);
  }, []);

  const handleSelectChange = (event) => {
    const { value } = event.target;
    if (value.includes("selectAll")) {
      setSelectedDepartments(
        selectedDepartments.length === departmentList.length
          ? []
          : departmentList.map((dept) => ({ _id: dept._id, deptName: dept.deptName }))
      );
    } else {
      const selectedIds = typeof value === "string" ? value.split(",") : value;
      setSelectedDepartments(
        selectedIds.map(
          (id) =>
            departmentList.find((dept) => dept._id === id) || {
              _id: id,
              deptName: "", 
            }
        )
      );
    }
  };
  

  useEffect(() => {
    if (departmentListData.length) {
      setDepartmentList(departmentListData);
    }
  }, [departmentListData]);

  // console.log("valuesEdit", valuesEdit);
  useEffect(() => {
    setNewFieldValue({
      title: valuesEdit?.title,
      moduleName: valuesEdit?.moduleName,
      submoduleName: valuesEdit?.submoduleName,
      department: valuesEdit?.department || [],
    });
    setSelectedDepartments(valuesEdit?.department || []);
  }, [valuesEdit]);

  const validationSchema = Yup.object({
    title: Yup.string("Cannot update with same title").required(
      "Name is required"
    ),
  });

  useEffect(() => {
    getAllModules();
    getSubModules();
  }, [])

  useEffect(() => {
    setModule(modulesList);
    const findSelectedModule = modulesList.filter(item => valuesEdit?.moduleName === item.companyModuleSlug);
    if (findSelectedModule.length) {
      setSelectedModule(findSelectedModule[0]);
    }

  }, [modulesList])

  // useEffect(() => {
  //   // setSubModule(subModulesList);
  //   console.log("subModulesList", subModulesList);

  //   // console.log("modulesList", findSelectedModule);
  //   const findSelectedSubModule = subModulesList.filter(item => valuesEdit?.submoduleName === item.companySubModuleSlug);

  //   if (findSelectedSubModule.length) {
  //     setSelectedSubModule(findSelectedSubModule[0]);
  //   }
  // }, [subModule])

  useEffect(() => {
    if(selectedmodule?._id){
      const getSubModule = subModulesList.filter((item)=> item.companyModule === selectedmodule?._id)
      setSubModule(getSubModule);
      const findSelectedSubModule = getSubModule.filter(item => valuesEdit?.submoduleName === item.companySubModuleSlug);

      if (findSelectedSubModule.length) {
        setSelectedSubModule(findSelectedSubModule[0]);
      } else {
        setSelectedSubModule([]);
      }
    }
  }, [selectedmodule])

  const formik = useFormik({
    initialValues: newFieldValue || {
      title: "",
      moduleName: "",
      submoduleName: "",
      department: [],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = {
        title: values.title,
        createdAt: valuesEdit.createdAt,
        deleted: valuesEdit.deleted,
        id: valuesEdit.id,
        isEditing: false,
        isEditingSub: false,
        subCategories: valuesEdit.subCategories,
        updatedAt: valuesEdit.updatedAt,
        moduleName: selectedmodule?.companyModuleSlug || "",
        submoduleName: selectedsubModule?.companySubModuleSlug || "",
        department: selectedDepartments,
      };
      const allData = {
        body: formData,
      };
      if (allData) {
        const id = localStorage.getItem("companyId");
        allData.url = BASE_URL + `categories?company_id=${id}`;
        dispatch(EditCategotyApi(allData));
        props.handleClose();
        props.listapiupdate();
      }
    },
  });

  const getAllModules = () => {
    const data = { url: BASE_URL + `companyModule/getAll` };
    dispatch(ModuleListListApi(data));
  };

  const getSubModules = () => {
    const data = { url: BASE_URL + `companySubModule/getAllSubModule` };
    dispatch(SubModuleListsListApi(data));
  };

  const handleModuleChange = (event, newValue) => {
    if (newValue) {
      setSelectedModule(newValue);
    }
  };

  const handleSubModuleChange = (event, newValue) => {
    if (newValue) {
      setSelectedSubModule(newValue);
    }
  };

  return (
    <div>
      <BootstrapDialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        className="modal-size-subcategory"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={props.handleClose}
        >
          <Typography variant="p" component="div" className="heading">
            Edit Category
          </Typography>
        </BootstrapDialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent dividers>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    className="input-fields"
                    id="name"
                    size="small"
                    variant="outlined"
                    name="title"
                    placeholder="Enter Category Name"
                    value={formik.values.title}
                    onChange={formik.handleChange}
                    error={formik.touched.title && Boolean(formik.errors.title)}
                    helperText={formik.touched.title && formik.errors.title}
                    fullWidth
                  />
                  <div>
                    <label>Module name</label>
                    <Autocomplete
                      options={
                        module && module.length > 0
                          ? module
                          : []
                      }
                      getOptionLabel={(option) => option?.title || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ borderRadius: "8px" }}
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              // handleClearSelection();
                            }
                          }}
                          variant="outlined"
                        />
                      )}
                      style={{
                        marginTop: "10px"
                      }}
                      value={selectedmodule}
                      onChange={handleModuleChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.title}
                        </li>
                      )}
                      clearable
                    // onClear={handleClearSelection}
                    />
                  </div>
                  <div style={{
                    marginTop: "10px"
                  }}>
                    <label>Sub-module name</label>
                    <Autocomplete
                      options={
                        subModule && subModule.length > 0
                          ? subModule
                          : []
                      }
                      style={{
                        marginTop: "10px"
                      }}
                      getOptionLabel={(option) => option?.title || ""}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          sx={{ borderRadius: "8px" }}
                          variant="outlined"
                          onBlur={() => {
                            if (!params.inputProps.value) {
                              // handleClearSelection();
                            }
                          }}
                        />
                      )}
                      value={selectedsubModule}
                      onChange={handleSubModuleChange}
                      renderOption={(props, option) => (
                        <li {...props} key={option._id}>
                          {option.title}
                        </li>
                      )}
                      clearable
                    // onClear={handleClearSelection}
                    />
                  </div>

                  <div style={{
                    marginTop: "10px"
                  }}></div>
                  <FormControl fullWidth sx={{ mb: 1 }}>
  <label style={{ marginBottom: "8px" }}>Select Department</label>
  <Select
    size="small"
    name="department"
    multiple
    value={selectedDepartments.map((dept) => dept._id)} // map to _id
    onChange={handleSelectChange}
    renderValue={(selected) =>
      selected
        .map(
          (id) => departmentList.find((dept) => dept._id === id)?.deptName
        )
        .join(", ")
    }
    sx={{ borderRadius: "8px" }}
  >
    {/* Select All / Unselect All */}
    <MenuItem
      value="selectAll"
      onClick={() => {
        setSelectedDepartments(
          selectedDepartments.length === departmentList.length
            ? [] // Unselect all
            : departmentList.map((dept) => ({ _id: dept._id, deptName: dept.deptName })) // Select all
        );
      }}
    >
      <Checkbox
        checked={selectedDepartments.length === departmentList.length}
      />
      <ListItemText
        primary={
          selectedDepartments.length === departmentList.length
            ? "Unselect All"
            : "Select All"
        }
      />
    </MenuItem>

    {/* Individual department selection */}
    {departmentList.map((option) => (
      <MenuItem key={option._id} value={option._id}>
        <Checkbox
          checked={selectedDepartments.some((dept) => dept._id === option._id)}
        />
        <ListItemText primary={option.deptName} />
      </MenuItem>
    ))}
  </Select>
  <small className="error">
    {formik.errors.department && formik.touched.department
      ? formik.errors.department
      : null}
  </small>
</FormControl>


                </Grid>
              </Grid>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              autoFocus
              type="submit"
              // onClick={props.handleClose}
              className="issue-modal-savebtn"
              color="success"
            >
              Update
            </Button>
            <Button
              variant="contained"
              className="issue-modal-cancelbtn"
              onClick={props.handleClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </form>
      </BootstrapDialog>
    </div>
  );
};

export default EditCategoryModel;
