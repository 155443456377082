/** @format */

import React, { useEffect, useState } from "react";
import Header from "./pages/Header";
import { Box, Grid, IconButton } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import { Link, useNavigate } from "react-router-dom";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../redux/apiConfig/BaseUrl";
import PropTypes from "prop-types";
import moment, { months } from "moment";
import {
  DeleteBroadcastApi,
  DeleteBroadcastRequest,
  DeleteBroadcastSuccess,
  ListBroadcastApi,
} from "../redux/actions/BroadcastMessage";
import ViewHover from "../images/ViewHover.svg";
import DeleteHover from "../images/DeleteHover.svg";
import Delete from "../images/Delete.svg";
import View from "../images/View.svg";
import { confirmAlert } from "react-confirm-alert";
import Progressbar from "../GlobalProgress/Progressbar";
import ProgressbarTransparent from "../GlobalProgress/ProgressbarTransparent";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const BroadcastMessage = () => {
  let count = 1;
  const navigate = useNavigate();
  let today = moment();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [broadcastData, setBroadcastData] = useState([]);
  const [indexHover, setIndexHover] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [uiRender, setUiRender] = useState(false);
  const [permission, setPermission] = useState({});
  const [loader, setLoader] = useState(true);
  const [content, setContent] = useState("");
  const [hover, setHover] = useState({
    View: false,
    Edit: false,
    Delete: false,
  });

  const stripHtmlTags = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  };

  const plainTextContent = stripHtmlTags(content);

  const broadcastList = useSelector(
    (state) =>
      state.broadcast &&
      state.broadcast.data &&
      state.broadcast.data.data &&
      state.broadcast.data.data.data &&
      state.broadcast.data.data.data.broadcast
  );

  const totalitems = useSelector(
    (state) =>
      state.broadcast &&
      state.broadcast.data &&
      state.broadcast.data.data &&
      state.broadcast.data.data.data &&
      state.broadcast.data.data.data.paginate
  );

  const BroadcaseMessageDelete = useSelector(
    (state) => state && state?.broadcast && state?.broadcast?.deleteMsg
  );

  const Loader = useSelector(
    (state) => state.broadcast && state.broadcast.loading
  );

  useEffect(() => {
    const permissions = localStorage.getItem("permissionData");
    if (permissions) {
      const newDAta = JSON.parse(permissions);
      setPermission(newDAta);
      setUiRender(!uiRender);
    }
  }, []);

  useEffect(() => {
    if (BroadcaseMessageDelete) {
      listBroadcast();
      dispatch(DeleteBroadcastSuccess(""));
    }
  }, [BroadcaseMessageDelete]);

  useEffect(() => {
    // setLoader(true);
    setBroadcastData(broadcastList);
    setTimeout(() => {
      setLoader(false);
    }, 2000);
  }, [broadcastList]);

  useEffect(() => {
    listBroadcast();
  }, [dispatch]);

  const data = {
    body: {
      search: "",
      pagination: {
        page: page, 
        per_page: rowsPerPage
      }
    },
  };

  const useStyles1 = makeStyles((theme) => ({
    root: {
      flexShrink: 0,
      marginLeft: theme.spacing(2.5),
    },
  }));

  function TablePaginationActions(props) {
    const classes = useStyles1();
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event) => {
      onPageChange(event, 0);
    };

    const handleBackButtonClick = (event) => {
      onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event) => {
      onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event) => {
      onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
      <div className={classes.root}>
        <IconButton
          onClick={handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label="first page"
        >
          {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={handleBackButtonClick}
          disabled={page === 0}
          aria-label="previous page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="next page"
        >
          {theme.direction === "rtl" ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="last page"
        >
          {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }

  TablePaginationActions.propTypes = {
    count: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    page: PropTypes.number.isRequired,
    rowsPerPage: PropTypes.number.isRequired,
  };

  const handleChangePage = (event, newPage) => {
    setLoader(true);
    console.log("newPage", newPage + 1);
    setPage(newPage + 1);
    data.body.pagination.page = newPage + 1;
    listBroadcast();
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };
  const handleChangeRowsPerPage = (event) => {
    setLoader(true);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    listBroadcast();
    setTimeout(() => {
      setLoader(false);
    }, 500);
  };

  const listBroadcast = () => {
    const id = localStorage.getItem("companyId");
    data.url = BASE_URL + `broadcast/index?company_id=${id}`;
    dispatch(ListBroadcastApi(data));
  };

  const handleClick = (row) => {
    navigate(`/broadcastMessage/broadcastMessageView`, { state: row });
  };

  const handleClickDelete = (id) => {
    confirmAlert({
      title: "Confirm Delete",
      message: "Are you sure to delete this Broadcast message?.",
      buttons: [
        {
          label: "OK",
          onClick: () => {
            const _id = localStorage.getItem("companyId");
            const data = {
              url: BASE_URL + `broadcast/${id}?company_id=${_id}`,
            };
            setLoader(true);
            dispatch(DeleteBroadcastApi(data));
            setTimeout(() => {
              setLoader(false);
            }, 2000);
          },
        },
        {
          label: "Cancel",
        },
      ],
    });
  };

  const handleSearch = (value) => {
    setSearchValue(value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      data.body.search = searchValue;
      listBroadcast();
    }
  };

  useEffect(() => {
    if (searchValue == "") {
      listBroadcast();
    }
  }, [searchValue]);

  return (
    <>
      <Header
        searchOnChange={handleSearch}
        handleKeyDown={handleKeyDown}
        searchValue={searchValue}
        broadcastListData={broadcastData}
        permission={permission}
      />
      {loader ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <ProgressbarTransparent play />
        </div>
      ) : (
        <Box style={{borderRadius:"6px"}}>
          <Box
            sx={{
              bgcolor: "#F6F8FC",
              height: "calc(100% - 62px)",
              mt: 1,
              p: 1,
              borderRadius:"4px",
              overflowX: "hidden",
              overflowY: "scroll",
              "&::-webkit-scrollbar": {
                width: 15,
              },
              "&::-webkit-scrollbar-track": {
                padding: "12px 5px",
                backgroundColor: "#CBD4E1",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "#64748B",
                borderRadius: 8,
              },
            }}
          >
            <TableContainer component={Paper} className="table-container">
              <Table size="large" aria-label="simple table">
                <TableHead sx={{ m: 4 }}>
                  <TableRow>
                    <TableCell sx={{ width: "10%" }} align="left">
                      S. No.
                    </TableCell>
                    <TableCell sx={{ width: "30%" }} align="left">
                      Content
                    </TableCell>
                    <TableCell sx={{ width: "20%" }} align="left">
                      To
                    </TableCell>
                    <TableCell sx={{ width: "20%" }} align="left">
                      Sent on
                    </TableCell>
                    <TableCell sx={{ width: "20%" }} align="center">
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {broadcastData &&
                    broadcastData
                      .map((row, i) => {
                        let count = i + page * rowsPerPage + 1;
                        return (
                          <TableRow
                            key={i}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell align="left">{count}</TableCell>

                            <TableCell align="left" component="th" scope="row">
                              <Box sx={{ fontWeight: "bold" }}>
                                {row.subject}
                              </Box>
                              <Box>
                                {row.message &&
                                  stripHtmlTags(
                                    (JSON.parse(row.message).blocks &&
                                      JSON.parse(row.message).blocks[0].text) ||
                                      (JSON.parse(row.message).ops &&
                                        JSON.parse(row.message).ops[0]
                                          .insert) ||
                                      "Content not available"
                                  )}
                              </Box>
                            </TableCell>

                            <TableCell align="left">
                              {row.role.map((role, index) => (
                                <Box
                                  key={index}
                                  className="Author"
                                  style={{
                                    backgroundColor: "skyblue",
                                    color: "white",
                                    marginBottom: "5px",
                                  }}
                                >
                                  {role}
                                </Box>
                              ))}
                            </TableCell>

                            <TableCell align="left">
                              {(() => {
                                const createdTime = moment(row.createdAt);
                                const now = moment();
                                const diffInSecs = now.diff(
                                  createdTime,
                                  "seconds"
                                );
                                const diffInMins = now.diff(
                                  createdTime,
                                  "minutes"
                                );
                                const diffInHours = now.diff(
                                  createdTime,
                                  "hours"
                                );
                                const diffInDays = now.diff(
                                  createdTime,
                                  "days"
                                );
                                const diffInWeeks = now.diff(
                                  createdTime,
                                  "weeks"
                                );
                                const diffInMonths = now.diff(
                                  createdTime,
                                  "months"
                                );
                                const diffInYears = now.diff(
                                  createdTime,
                                  "years"
                                );

                                if (diffInSecs < 60) {
                                  return `${diffInSecs} seconds ago`;
                                } else if (diffInMins < 60) {
                                  return `${diffInMins} minutes ago`;
                                } else if (diffInHours < 24) {
                                  return `${diffInHours} hours ago`;
                                } else if (diffInDays < 7) {
                                  return `${diffInDays} days ago`;
                                } else if (diffInWeeks < 4) {
                                  return `${diffInWeeks} weeks ago`;
                                } else if (diffInMonths < 12) {
                                  return `${diffInMonths} months ago`;
                                } else {
                                  return `${diffInYears} years ago`;
                                }
                              })()}
                            </TableCell>
                            <TableCell align="left">
                              <Box
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <Box
                                  align="center"
                                  sx={{
                                    minWidth: 150,
                                    gap: "7px",
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <Tooltip title="View">
                                  <img
                                    src={
                                      hover.View && indexHover === i
                                        ? ViewHover
                                        : View
                                    }
                                    alt="VIEW"
                                    style={{
                                      height: "35px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => handleClick(row)}
                                    onMouseOver={() => {
                                      setHover({ View: true });
                                      setIndexHover(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ View: false });
                                    }}
                                  />
                                  </Tooltip>
                                  <Tooltip title="Delete">
                                  <img
                                    onClick={() => handleClickDelete(row._id)}
                                    src={
                                      hover.Delete && indexHover === i
                                        ? DeleteHover
                                        : Delete
                                    }
                                    alt="Delete"
                                    style={{
                                      height: "35px",
                                      cursor: "pointer",
                                    }}
                                    onMouseOver={() => {
                                      setHover({ Delete: true });
                                      setIndexHover(i);
                                    }}
                                    onMouseLeave={() => {
                                      setHover({ Delete: false });
                                    }}
                                  />
                                  </Tooltip>
                                </Box>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box sx={{ bgcolor: "#F6F8FC", p: 2 }}>
            <Grid container spacing={1} sx={{ alignItems: "center" }}>
              <Grid item xs={12}>
                <TablePagination
                  className="tablepagination"
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={1}
                  count={totalitems?.total_item}
                  rowsPerPage={rowsPerPage}
                  pageSize={rowsPerPage}
                  page={page - 1}
                  SelectProps={{
                    inputProps: { "aria-label": "Items per Page" },
                    native: true,
                  }}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  labelRowsPerPage={"Items per Page"}
                  ActionsComponent={TablePaginationActions}
                />
              </Grid>
            </Grid>
          </Box>
          <p>Copyright ©2024 <a href="https://www.hoperesearchgroup.com/">HOPE RESEARCH GROUP.</a> All Right Reserved.</p>
        </Box>
      )}
    </>
  );
};

export default BroadcastMessage;
